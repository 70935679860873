import React, { useEffect, useContext } from "react";
import Chat, { Bubble, useMessages } from "@chatui/core";
import "@chatui/core/dist/index.css";
import { awscontext } from "./../../awscontext";
import i18n from "../../../config/i18n";
import { withNamespaces } from "react-i18next";

function ChatComp(props) {
  const { messages, appendMsg, resetList, setTyping } = useMessages([]);
  const { setAppendMsg } = useContext(awscontext);

  useEffect(() => {
    setAppendMsg(appendMsg, setTyping, resetList);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function handleSend(type, val) {
    props.sendTextMessage(val);
    if (type === "text" && val.trim()) {
      setTyping(true);
      appendMsg({
        type: "text",
        content: { text: val },
        position: "right",
      });
    }
  }
  function renderMessageContent(msg) {
    const { content } = msg;
    return <Bubble content={content.text} />;
  }

  return (
    <div
      className="col p-0"
      style={{ height: 500, display: props.panelVisibility ? "" : "none" }}
    >
      <Chat
        height="400px"
        navbar={{ title: i18n.t("Proctor_chat") }}
        locale="en-US"
        messages={messages}
        renderMessageContent={renderMessageContent}
        placeholder={i18n.t("Chat_message")}
        onSend={handleSend}
      />
    </div>
  );
}
export default withNamespaces()(ChatComp);
