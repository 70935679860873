/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef } from "react";
import ExamStudent from "./app/components/connection/ExamStudent";
import SecurityCheck from "./app/components/SecurityCheck";
import Header from "./app/components/general/Header";
import ExamUrlScreen from "./app/components/ExamUrlScreen";
import TermsOfAgreementScreen from "./app/components/TermsOfAgreementScreen";
import SystemCheck from "./app/components/SystemCheck";
import ExamFinishedScreen from "./app/components/ExamFinishedScreen";
import * as faceapi from "face-api.js";
import ChatIcon from "./app/components/chat/ChatIcon";
import CheckAllowedMaterial from "./app/components/system-check-steps/CheckAllowedMaterial";
import CheckAudio from "./app/components/system-check-steps/CheckAudio";
import CheckInternetConnection from "./app/components/system-check-steps/CheckInternetConnection";
import CheckMaterialDestroyed from "./app/components/system-check-steps/CheckMaterialDestroyed";
import CheckMobileConnection from "./app/components/system-check-steps/CheckMobileConnection";
import CheckRoomScan from "./app/components/system-check-steps/CheckRoomScan";
import CheckNumberOfDisplays from "./app/components/system-check-steps/CheckNumberOfDisplays";
import CheckStreaming from "./app/components/system-check-steps/CheckStreaming";
import CheckToiletBreak from "./app/components/system-check-steps/CheckToiletBreak";
import CheckWebcam from "./app/components/system-check-steps/CheckWebcam";
import querystring from "querystring";
import axios from "axios";
import LoadingIcon from "./app/LoadingIcon";
import useSound from "use-sound";
import uniqid from "uniqid";
import { awscontext } from "./app/awscontext";
import ReactNotification from "react-notifications-component";
import { store } from "react-notifications-component";
import { FaPhone, FaRegHourglass } from "react-icons/fa";
import OpenViduConnector from "./utils/OpenViduConnector";
import DownloadScreen from "./app/components/DownloadScreen";
import i18n from "./config/i18n";
import { withNamespaces } from "react-i18next";

const config = require("./config/awsconfig.json");

function App(props) {
  const { examFinishedByProctor, examForceFinishedByAdmin } =
    useContext(awscontext);
  const videoRef = useRef(null);
  const intervalIdRef = useRef(null);
  const [pageState, setPageState] = useState(0);
  const [chatSession, setChatSession] = useState(null);
  const [callSession, setCallSession] = useState(null);
  const [banapublisher, setPublishers] =useState(null);
  const [successfulReconnection, setSuccessfulReconnection] = useState(false);
  const [information, setInformation] = useState({
    student: {
      examRules: {
        mobileCameraUsage: false,
      },
    },
    session: {
      proctorServiceType: "Record_and_Review",
      systemCheck: {
        preExamMaterialCheck: true,
      },
    },
  });
  const [settings, setSettings] = useState({
    pc: {
      audioVideo: null,
      webcam: { active: false, session: null, recorder: null, device: null },
      screenShare: { active: false, session: null, recorder: null },
    },
    mobile_0: { active: true, session: null },
  });
  const [incomingCallInformation, setIncomingCallInformation] = useState({
    proctorId: null,
    meetingId: null,
    status: "FINISHED",
  });
  const [selectedDevice, setSelectedDevice] = useState(null);
  let content = "EXAM";
  const [dependencyReady, setDependencyReady] = useState(false);
  const [interceptorReady, setInterceptorReady] = useState(false);

  const [playNotificationSound, { stopNotificationSound }] = useSound(
    `https://${config.s3.files}.s3.eu-west-1.amazonaws.com/pristine-609.mp3`,
    {
      volume: 0.9,
      forceSoundEnabled: true,
    }
  );

  // const [latestTechnicalChatMessageTime, setLatestTechnicalChatMessageTime] =
  //   useState(0);
  let loginTimestamp = 0;
  let technicalMessageCount = 0;
  
  useEffect(() => {
    if (pageState === 4 && successfulReconnection) {
      setPageState(5);
    }
  }, [pageState]);
  useEffect(() => {
    if (examFinishedByProctor) {
      store.addNotification({
        title: i18n.t("Finish_Exam"),
        message: i18n.t("Exam_ended_by_proctor"),
        type: "info",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3500,
          onScreen: true,
        },
      });
      if (
        information.session.systemCheck.postExamRoomScan ||
        information.session.systemCheck.postExamMaterialCheck
      ) {
        setPageState(6);
      } else {
        setPageState(7);
      }
    }
  }, [examFinishedByProctor]);

  useEffect(() => {
    if (examForceFinishedByAdmin) {
      store.addNotification({
        title: i18n.t("Finish_Exam"),
        message: i18n.t("Exam_ended_by_admin"),
        type: "info",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3500,
          onScreen: true,
        },
      });
      if (
        information.session.systemCheck.postExamRoomScan ||
        information.session.systemCheck.postExamMaterialCheck
      ) {
        setPageState(6);
      } else {
        setPageState(7);
      }
    }
  }, [examForceFinishedByAdmin]);

  useEffect(() => {
    const retrieveToken = async () => {
      const res = await axios.post(
        config.api.configuration.token_base_path,
        querystring.stringify({
          grant_type: "client_credentials",
          scope: config.api.configuration.scope,
        }),
        {
          auth: {
            username: config.api.configuration.auth.username,
            password: config.api.configuration.auth.password,
          },
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      return res.data.access_token;
    };
    
    const createAxiosInterceptor = () => {
      retrieveToken().then((token) => {
        axios.interceptors.request.use(
          async (config) => {
            config.headers = {
              Authorization: `${token}`,
            };
            return config;
          },
          (error) => {
            Promise.reject(error);
          }
        );
        setInterceptorReady(true);
      });
    };
    createAxiosInterceptor();
  }, []);

  useEffect(() => {
    const loadDetectionDependencies = async () => {
      await faceapi.nets.tinyFaceDetector.loadFromUri(
        `${config.api.s3}${config.detection_model.path}`
      );
      await faceapi.nets.faceLandmark68Net.loadFromUri(
        `${config.api.s3}${config.detection_model.path}`
      );
      await faceapi.nets.faceRecognitionNet.loadFromUri(
        `${config.api.s3}${config.detection_model.path}`
      );
      await faceapi.nets.faceExpressionNet.loadFromUri(
        `${config.api.s3}${config.detection_model.path}`
      );
    };
    loadDetectionDependencies().then(() => {
      setDependencyReady(true);
    });
  }, []);

  useEffect(() => {
    if (incomingCallInformation.status === "FINISHED" && callSession) {
      callSession.disconnect();
      setCallSession(null);
    }
  }, [incomingCallInformation]);

  useEffect(() => {
    if (incomingCallInformation.status === "WAITING") {
      play();
      intervalIdRef.current = setInterval(() => {
        play();
      }, 8000);
    } else if (
      incomingCallInformation.status === "ACCEPTED" ||
      incomingCallInformation.status === "FINISHED"
    ) {
      stop();
      clearInterval(intervalIdRef.current);
    }
  }, [incomingCallInformation.status]);

  useEffect(() => {
    if (pageState === 7) {
      videoRef.current = null;
    }
  }, [])
  // useEffect(() => {
  //   let intervalId = null;
  //   if (videoRef.current) {
  //     const video = videoRef.current;
  //     console.log(video);
  //     video.oncanplay = () => {
  //       console.log("I can play now");
  //     };
  //   }
  //   return () => {
  //     if (intervalId) {
  //       clearInterval(intervalId);
  //     }
  //   };
  // }, [videoRef.current]);
  const loadTawkTo = (propertyId, key, configuration) => {
    if (!window) {
      throw new Error("DOM is unavailable");
    }

    window.Tawk_API = window.Tawk_API || {};
    window.Tawk_LoadStart = new Date();
    window.Tawk_API.visitor = configuration;
    // window.Tawk_API.onLoad = function () {
    //   window.Tawk_API.setAttributes(configuration, function (err) {
    //     if (err) {
    //       console.log("Tawk to set attribute error: ", err);
    //     }
    //   });
    // };

    const tawk = document.getElementById("tawkId");
    if (tawk) {
      // Prevent TawkTo to create root script if it already exists
      return window.Tawk_API;
    }

    if (!key) {
      throw new Error(
        "Key not provided. Get key from tawk dashboard - Direct Chat Link"
      );
    }

    const script = document.createElement("script");
    script.id = "tawkId";
    script.async = true;
    script.src = "https://embed.tawk.to/" + propertyId + "/" + key;
    script.charset = "UTF-8";
    script.setAttribute("crossorigin", "*");

    const first_script_tag = document.getElementsByTagName("script")[0];
    if (!first_script_tag || !first_script_tag.parentNode) {
      throw new Error("DOM is unavailable");
    }

    first_script_tag.parentNode.insertBefore(script, first_script_tag);
  };

  const getLatestTechnicalChatMessage = (meetingId, studentName) => {
    const iFrameList = document.getElementsByTagName("iframe");
    const tawkToIFrame = [].slice
      .call(iFrameList)
      .find(
        (iframe) =>
          iframe.offsetHeight / iframe.offsetWidth > 1 && iframe.id !== "exam"
      );
    if (tawkToIFrame) {
      const currentDocument = tawkToIFrame.contentWindow.document;
      const messageBubbleCollection = currentDocument.getElementsByClassName(
        "tawk-message-bubble"
      );
      // console.log(messageBubbleCollection.length);
      const chatInputElement = currentDocument.getElementById(
        "tawk-chatinput-container"
      );
      if (chatInputElement && chatInputElement.style.display !== "none") {
        let agentName;
        try {
          agentName = currentDocument
            .getElementsByClassName("tawk-agent-name")[0]
            .innerHTML.trim();
        } catch (err) {
          agentName = "Technical Agent";
        }

        const messageBubbleList = [].slice.call(messageBubbleCollection);
        let currentTechnicalMessageCount = 0;
        messageBubbleList.forEach((messageBubble) => {
          try {
            const messageTime = calculateUnixTimeStampFromTime(
              messageBubble.getElementsByTagName("time")[0].innerHTML
            );
            if (messageTime >= loginTimestamp) {
              if (
                messageBubble.getElementsByClassName("tawk-visitor-chat")
                  .length > 0
              ) {
                const messageText =
                  messageBubble.getElementsByTagName("span")[0].innerHTML;
                if (messageText !== "New messages") {
                  currentTechnicalMessageCount++;
                  if (currentTechnicalMessageCount > technicalMessageCount) {
                    console.log(
                      `${studentName}(${messageTime}): ${messageText}`
                    );
                    saveChatMessageToDynamo(
                      meetingId,
                      messageText,
                      studentName,
                      new Date().getTime(),
                      "Student"
                    );
                  }
                }
              } else if (
                messageBubble.getElementsByClassName("tawk-agent-chat").length >
                0
              ) {
                const messageText =
                  messageBubble.getElementsByTagName("span")[0].innerHTML;
                if (messageText !== "New messages") {
                  currentTechnicalMessageCount++;
                  if (currentTechnicalMessageCount > technicalMessageCount) {
                    console.log(`${agentName}(${messageTime}): ${messageText}`);
                    saveChatMessageToDynamo(
                      meetingId,
                      messageText,
                      agentName,
                      new Date().getTime(),
                      "Agent"
                    );
                  }
                }
              }
            }
          } catch (err) {
            console.log("unknown message bubble: ", messageBubble);
          }
        });
        technicalMessageCount = currentTechnicalMessageCount;
      }
    }
  };

  const calculateUnixTimeStampFromTime = (time) => {
    const hour = time.split(":")[0];
    const minute = time.split(":")[1];
    return new Date(
      new Date().getUTCFullYear(),
      new Date().getUTCMonth(),
      new Date().getUTCDate(),
      hour,
      minute,
      59,
      999
    ).getTime();
  };

  const updateLoginTimestamp = (val) => {
    loginTimestamp = val;
  };

  const periodicallyGetTechnicalChatMessages = (meetingId, studentName) => {
    setInterval(
      getLatestTechnicalChatMessage.bind(null, meetingId, studentName),
      5000
    );
  };

  const sendTextMessage = (messageText) => {
    const messageInfo = {
      text: messageText,
      role: "STUDENT",
    };
    if (messageText.trim()) {
      chatSession
        .signal({
          data: JSON.stringify(messageInfo),
          type: "message",
        })
        .then(() => {
          saveChatMessageToDynamo(
            `${information.session.sessionId}${information.student.studentId}`,
            messageText,
            `${information.student.name} ${information.student.lastname}`,
            new Date().getTime(),
            "Student"
          );
        })
        .catch((error) => {
          console.error("Err:", error);
        });
    }
  };

  const getBasicSystemCheckConfiguration = () => {
    const basicSystemCheckConfiguration = [];
    basicSystemCheckConfiguration.push({
      prev: "Terms & Conditions",
      renderName: "Internet Connection Check",
      render: CheckInternetConnection,
      renderIndex: 0,
    });
    if (information.session.systemCheck.additionalDisplayCheck) {
      basicSystemCheckConfiguration[
        basicSystemCheckConfiguration.length - 1
      ].next = "Display Check";
      basicSystemCheckConfiguration.push({
        next: `Webcam Check`,
        prev: `Internet Connection Check`,
        renderName: "Additional Display Check",
        render: CheckNumberOfDisplays,
        renderIndex: 0,
      });
    } else {
      basicSystemCheckConfiguration[
        basicSystemCheckConfiguration.length - 1
      ].next = "Webcam Check";
    }
    basicSystemCheckConfiguration.push({
      next: `Audio Check`,
      prev: `Additional Display Check`,
      renderName: "Webcam Check",
      render: CheckWebcam,
      renderIndex: 0,
    });
    basicSystemCheckConfiguration.push({
      prev: `Webcam Check`,
      render: CheckAudio,
      renderName: "Audio Check",
      next: `Streaming Check`,
      renderIndex: 0,
    });
    return basicSystemCheckConfiguration;
  };

  const getStreamingCheckConfiguration = () => {
    const streamingCheckConfiguration = [];
    if (information) {
      streamingCheckConfiguration.push({
        prev: `Audio Check`,
        renderName: "Streaming Check",
        render: CheckStreaming,
        renderIndex: 0,
      });
      if (information.session.proctorServiceType === "Live_Proctoring_Plus") {
        if (information.student.examRules.mobileCameraUsage) {
          for (let i = 0; i < information.session.allowedOutsourceCamera; i++) {
            streamingCheckConfiguration[
              streamingCheckConfiguration.length - 1
            ].next = `Mobile Camera Check`;
            streamingCheckConfiguration.push({
              next: `Photo & ID Verification`,
              prev: `Streaming Check`,
              renderName: `Mobile Camera Check-${i + 1}`,
              renderIndex: i,
              render: CheckMobileConnection,
            });
          }
        } else {
          streamingCheckConfiguration[
            streamingCheckConfiguration.length - 1
          ].next = `Photo & ID Verification`;
        }
      } else {
        if (information.student.examRules.mobileCameraUsage) {
          streamingCheckConfiguration[
            streamingCheckConfiguration.length - 1
          ].next = `Mobile Camera Check`;
          streamingCheckConfiguration.push({
            next: `Photo & ID Verification`,
            prev: `Streaming Check`,
            renderName: "Mobile Camera Check",
            renderIndex: 0,
            render: CheckMobileConnection,
          });
        } else {
          streamingCheckConfiguration[
            streamingCheckConfiguration.length - 1
          ].next = `Photo & ID Verification`;
        }
      }
    }
    return streamingCheckConfiguration;
  };

  const getOnboardingProcessConfiguration = () => {
    const onboardingProcessConfiguration = [];
    if (information) {
      onboardingProcessConfiguration.push({
        renderName: "Photo & ID Verification",
        render: SecurityCheck,
        next: "Exam Environment Check",
        renderIndex: 0,
      });
      if (information.student.examRules.mobileCameraUsage) {
        onboardingProcessConfiguration[0].prev = "Mobile Camera Check";
      } else {
        onboardingProcessConfiguration[0].prev = "Streaming Check";
      }
      onboardingProcessConfiguration.push({
        prev: `Photo & ID Verification`,
        renderName: "Exam Environment Check",
        render: CheckRoomScan,
        renderIndex: 0,
      });
      if (information.session.systemCheck.preExamMaterialCheck) {
        onboardingProcessConfiguration[
          onboardingProcessConfiguration.length - 1
        ].next = "Material Check";
        onboardingProcessConfiguration.push({
          prev: `Exam Environment Check`,
          renderName: "Material Check",
          render: CheckAllowedMaterial,
          next: "Toilet Break Check",
          renderIndex: 0,
        });
        onboardingProcessConfiguration.push({
          prev: "Material Check",
          render: CheckToiletBreak,
          renderName: "Toilet Break Check",
          next: "To Exam",
          renderIndex: 0,
        });
      } else {
        onboardingProcessConfiguration[
          onboardingProcessConfiguration.length - 1
        ].next = "Toilet Break Check";
        onboardingProcessConfiguration.push({
          prev: information.session.systemCheck.preExamMaterialCheck
            ? "Material Check"
            : "Exam Environment Check",
          render: CheckToiletBreak,
          renderName: "Toilet Break Check",
          next: "To Exam",
          renderIndex: 0,
        });
      }
    }
    return onboardingProcessConfiguration;
  };

  const getPostSystemCheckConfiguration = () => {
    const postSystemCheckConfiguration = [];
    if (information) {
      postSystemCheckConfiguration.push({
        prev: `Exam`,
        renderIndex: 0,
      });
      if (information.session.systemCheck.postExamRoomScan) {
        postSystemCheckConfiguration[
          postSystemCheckConfiguration.length - 1
        ].render = CheckRoomScan;
        postSystemCheckConfiguration[
          postSystemCheckConfiguration.length - 1
        ].renderName = "Post-Exam Environment Check";
        if (information.session.systemCheck.postExamMaterialCheck) {
          postSystemCheckConfiguration[
            postSystemCheckConfiguration.length - 1
          ].next = `Post-Exam Material Check`;
        }
      }
      if (information.session.systemCheck.postExamMaterialCheck) {
        if (information.session.systemCheck.postExamRoomScan) {
          postSystemCheckConfiguration.push({
            render: CheckMaterialDestroyed,
            renderName: "Post-Exam Material Check",
            prev: `Check Post-Exam Environment`,
            renderIndex: 0,
          });
        } else {
          postSystemCheckConfiguration[
            postSystemCheckConfiguration.length - 1
          ].render = CheckMaterialDestroyed;
          postSystemCheckConfiguration[
            postSystemCheckConfiguration.length - 1
          ].renderName = "Post-Exam Material Check";
        }
      }

      postSystemCheckConfiguration[
        postSystemCheckConfiguration.length - 1
      ].next = `Finish Exam`;
    }
    return postSystemCheckConfiguration;
  };

  const saveChatMessageToDynamo = async (
    meetingId,
    messageText,
    messageFrom,
    messageTime,
    role
  ) => {
    const databaseParams = {
      ChatId: uniqid(),
      MeetingId: meetingId,
      Message: messageText,
      Person: messageFrom,
      Role: role,
      Time: messageTime,
    };
    await axios.post(`${config.api.invokeUrl}/chats`, databaseParams);
  };

  const appropriateSessionProctoringType = () => {
    if (
      information.session.proctorServiceType === "Record_and_Review" ||
      information.session.proctorServiceType === "Record_and_Review_Lite"
    ) {
      return false;
    } else {
      return true;
    }
  };

  const [play, { stop }] = useSound(
    `https://${config.s3.files}.s3.eu-west-1.amazonaws.com/call3.mp3`,
    {
      volume: 0.9,
      forceSoundEnabled: true,
    }
  );

  const subscribeToSession = async (sessionId) => {
    const openviduConnector = new OpenViduConnector();
    const sessionProperties = {
      customSessionId: sessionId,
    };
    await openviduConnector.createSession(sessionProperties);
    const session = openviduConnector.initializeSession();
    const connectionProperties = {
      role: "SUBSCRIBER",
    };
    const connection = await openviduConnector.connectToSession(
      sessionProperties.customSessionId,
      connectionProperties
    );
    await openviduConnector.joinSession(
      session,
      connection,
      `${information.student.name} ${information.student.lastname}`,
      "voice",
      null,
      "videoproctor"
    );
    setCallSession(session);
  };

  const unsubcribeFromSession = () => {
    if (callSession) {
      callSession.disconnect();
      setCallSession(null);
    }
  };

  const setChangeIncomingCallInformationStatus = () => {
    if (incomingCallInformation.status === "WAITING") {
      setIncomingCallInformation((prevIncomingCallInformation) => ({
        ...prevIncomingCallInformation,
        status: "CREATING_CONNECTION",
      }));
      subscribeToSession(`${incomingCallInformation.meetingId}-voice`)
        .then(() => {
          setIncomingCallInformation((prevIncomingCallInformation) => ({
            ...prevIncomingCallInformation,
            status: "ACCEPTED",
          }));
          store.addNotification({
            title: null,
            message: i18n.t("Proctor_Call_Started"),
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3500,
              onScreen: true,
            },
          });

          settings.pc.webcam.session
            .signal({
              data: JSON.stringify({
                state: "CALL_ACCEPTED",
              }),
              type: "system",
            })
            .then(() => {
              console.log("Call accepted");
            })
            .catch((error) => {
              console.error("err:", error);
            });
        })
        .catch((err) => {
          console.log("Error while subscribing the voice channel", err);
        });
    } else if (incomingCallInformation.status === "ACCEPTED") {
      setIncomingCallInformation({
        proctorId: null,
        meetingId: null,
        status: "FINISHED",
      });
      store.addNotification({
        title: null,
        message: i18n.t("Proctor_Call_Ended"),
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3500,
          onScreen: true,
        },
      });
      unsubcribeFromSession();
      settings.pc.webcam.session
        .signal({
          data: JSON.stringify({
            state: "STUDENT_CALL_ENDED",
          }),
          type: "system",
        })
        .then(() => {
          console.log("Student ended the call");
        })
        .catch((error) => {
          console.error("err:", error);
        });
    }
  };

  const RenderIncomingCallStatus = () => {
    if (incomingCallInformation.status === "WAITING") {
      return (
        <button
          className="btn buttons call_button"
          style={{ backgroundColor: "orange", borderColor: "orange" }}
          onClick={setChangeIncomingCallInformationStatus}
        >
          <FaPhone size={25} color={"white"} />
        </button>
      );
    } else if (incomingCallInformation.status === "CREATING_CONNECTION") {
      return (
        <button
          className="btn buttons call_button"
          style={{ backgroundColor: "#29377e", borderColor: "#29377e" }}
        >
          <FaRegHourglass size={25} color={"white"} />
        </button>
      );
    } else if (incomingCallInformation.status === "ACCEPTED") {
      return (
        <button
          className="btn buttons call_button"
          style={{
            backgroundColor: "rgba(90, 188, 42, 1)",
            borderColor: "rgba(90, 188, 42, 1)",
          }}
          onClick={setChangeIncomingCallInformationStatus}
        >
          <FaPhone size={25} color={"white"} />
        </button>
      );
    }
  };

  const mainContent = () => {
    return (
      <React.Fragment>
        <ReactNotification />
        {pageState === 5 ? null : (
          <Header
            step={pageState}
            content={content}
            information={information}
          />
        )}
        {pageState === 0 ? (
          <ExamUrlScreen
            content={content}
            setPageState={setPageState}
            setInformation={setInformation}
            information={information}
            path={props.location.pathname}
            loadTawkTo={loadTawkTo}
            periodicallyGetTechnicalChatMessages={
              periodicallyGetTechnicalChatMessages
            }
            updateLoginTimestamp={updateLoginTimestamp}
            setSuccessfulReconnection={setSuccessfulReconnection}
          />
        ) : null}
        {pageState === 1 ? (
          <TermsOfAgreementScreen
            setPageState={setPageState}
            studentId={information.student.studentId}
            sessionId={information.session.sessionId}
            instituteId={information.institute.instituteId}/>
        ) : null}
        {pageState === 2 ? (
          <SystemCheck
            content={content}
            configuration={getBasicSystemCheckConfiguration()}
            pageState={pageState}
            setPageState={setPageState}
            setSelectedDevice={setSelectedDevice}
            selectedDevice={selectedDevice}
            information={information}
            {...props}
          />
        ) : null}
        {pageState === 3 ? (
          <SystemCheck
            content={content}
            setPublisher={setPublishers}
            configuration={getStreamingCheckConfiguration()}
            setChatSession={setChatSession}
            pageState={pageState}
            setPageState={setPageState}
            settings={settings}
            setSettings={setSettings}
            setSelectedDevice={setSelectedDevice}
            selectedDevice={selectedDevice}
            information={information}
            setIncomingCallInformation={setIncomingCallInformation}
            // chatIconOnPress={chatIconOnPress}
            playNotificationSound={playNotificationSound}
            stopNotificationSound={stopNotificationSound}
          // {...props}
          />
        ) : null}
        {pageState === 4 ? (
          <SystemCheck
            content={content}
            configuration={getOnboardingProcessConfiguration()}
            setChatSession={setChatSession}
            pageState={pageState}
            setPageState={setPageState}
            settings={settings}
            setSettings={setSettings}
            setSelectedDevice={setSelectedDevice}
            selectedDevice={selectedDevice}
            information={information}
            setIncomingCallInformation={setIncomingCallInformation}
            // chatIconOnPress={chatIconOnPress}
            playNotificationSound={playNotificationSound}
            stopNotificationSound={stopNotificationSound}
            dependencyReady={dependencyReady}
          // {...props}
          />
        ) : null}
        {pageState === 5 ? (
          <ExamStudent
            information={information}
            publisher={banapublisher}
            settings={settings}
            pageState={pageState}
            setPageState={setPageState}
            incomingCallInformation={incomingCallInformation}
            setIncomingCallInformation={setIncomingCallInformation}
            selectedDevice={selectedDevice}
            banapublisher={banapublisher}
            content={content}
          />
        ) : null}
        {pageState === 6 ? (
          <SystemCheck
            content={content}
            configuration={getPostSystemCheckConfiguration()}
            setChatSession={setChatSession}
            pageState={pageState}
            setPageState={setPageState}
            settings={settings}
            setSettings={setSettings}
            setSelectedDevice={setSelectedDevice}
            selectedDevice={selectedDevice}
            information={information}
            setIncomingCallInformation={setIncomingCallInformation}
            // chatIconOnPress={chatIconOnPress}
            playNotificationSound={playNotificationSound}
            stopNotificationSound={stopNotificationSound}
          />
        ) : null}
        {pageState === 7
          ?
          <ExamFinishedScreen
            information={information}
            unsubcribeFromSession={unsubcribeFromSession}
          />
          :
          null
        }
        {/* {pageState === -1 ? <ProblemPage data={data} /> : null} */}
        {pageState === 8 ? <DownloadScreen /> : null}
        {settings.pc.webcam.active &&
          pageState > 2 &&
          appropriateSessionProctoringType()
          ? RenderIncomingCallStatus()
          : null}
        {settings.pc.webcam.active &&
          pageState > 2 &&
          appropriateSessionProctoringType() ? (
          <ChatIcon sendTextMessage={sendTextMessage} />
        ) : null}

        <div
          id="videoproctor"
          ref={videoRef}
          style={{ position: "absolute", display: "none" }}
        />
      </React.Fragment>
    );
  };

  return interceptorReady ? mainContent() : <LoadingIcon />;
}
export default withNamespaces()(App);
