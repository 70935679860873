import React, { Component } from "react";
import ReactLoading from "react-loading";

export default class LoadingIcon extends Component {
  render() {
    return (
      <div
        style={{
          display: "flex",
          height: "40vh",
          alignItems: "center",
          justifyContent: "center",
        }}
        className="container"
      >
        <ReactLoading type="bars" color="#29377e" height={"10%"} width={"10%"} />
      </div>
    );
  }
}
