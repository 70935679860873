import { useState } from "react";
import StorageConnector from "lotec-storage-connector";
const config = require("../../config/awsconfig.json");

export const useStorage = (bucketName) => {
  const [storageConnector] = useState(
    () =>
      new StorageConnector({
        accessKeyId: config.aws.accessKeyId,
        secretAccessKey: config.aws.secretAccessKey,
        region: config.aws.region,
        bucketName,
      })
  );

  const uploadObject = async (file, fileName, fileBasePath) => {
    const metadata = {
      "uploaded-from": "rps-student",
    };
    const uploadParams = storageConnector.constructUploadParams(
      "public-read",
      fileBasePath + fileName,
      file.type,
      file,
      metadata
    );
    storageConnector.uploadToStorageService(uploadParams);
  };

  const listObjects = async (prefix) => {
    const params = { Prefix: prefix };
    const listObjects = await storageConnector.listObjectsFromStorageService(
      params
    );
    return listObjects;
  };

  const getBaseUrl = () => {
    return `https://${bucketName}.s3-${config.aws.region}.amazonaws.com`;
  };

  return [uploadObject, listObjects, getBaseUrl];
};
