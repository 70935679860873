import React, { useContext, useEffect, useState } from "react";

// var shajs = require("sha.js");
import config from "../../config/awsconfig.json";
import { Modal } from "react-bootstrap";
import { awscontext } from "../awscontext";
import i18n from "../../config/i18n";
import { withNamespaces } from "react-i18next";
import { BsWindows } from "react-icons/bs";
import { GrApple } from "react-icons/gr";
import { FaChevronCircleRight } from "react-icons/fa";
function DownloadScreen(props) {
  const context = useContext(awscontext);
  const [showFinishExam, setShowFinishExam] = useState(false);
  const [showFinishMacExam, setShowFinishMacExam] = useState(false);
  const [examCheckLink, setLink] = useState();
  //console.log("Path:",window.location.href);
  //console.log("Path2:",context.pathDownload);
  // const mainContent = () => {
  //     return (

  //       <React.Fragment>
  //     <h3>LDB must be used in this exam.</h3>
  //     <br></br>
  //     <h3>You can only attend this exam via LDB.</h3>

  //     <br></br>
  //     <h3>If you don't have LDB please download it.</h3>

  //         <br></br>
  //         <a className="btn btn-primary" style={{textColor: "white"}} href={`${config.api.streamS3}/LDB/Windows/SmartLock.zip`} >

  //           Download
  //       </a>
  //       </React.Fragment>

  //     );
  //   };
  const handleCloseFinishModal = () => {
    setShowFinishExam(false);
  };
  const handleCloseFinishMacModal = () => {
    setShowFinishMacExam(false);
  };
  const informationModal = () => {
    setShowFinishExam(true);
  };
  const informationMacModal = () => {
    setShowFinishMacExam(true);
  };

  useEffect(() => {
    let currentUrl = window.location.pathname;
    setLink(currentUrl);
  }, []);

  return (
    <div className="d-flex flex-column justify-content-start align-items-center h-100 currentSetupContent">
      <div className="col-md-8 d-flex justify-content-center align-items-center h-100 p-5 mt-5">
        <div
          className="d-flex justify-content-start align-items-center "
          style={{
            backgroundColor: "#29377e",
            color: "white",
            borderRadius: 20,
            height: "70%",
          }}
        >
          <div style={{ fontSize: 20, padding: 50 }}>
            <br></br>
            <br></br>
            <h5 className="d-flex justify-content-center">
              {i18n.t(
                "SmartLock_must_be_used_in_this_exam_You_can_only_attend_this_exam_via_SmartLock"
              )}
            </h5>
            <h5 className="d-flex justify-content-center mt-5">
              {i18n.t("If_you_dont_have_SmartLock_please_download_it")}
            </h5>
            <br></br>

            <div className="d-flex">
              <div className="item">
                <div className="p-0">
                  <a
                    className="d-flex justify-content-center btn badge-light"
                    onClick={informationMacModal}
                    data-toggle="modal"
                    href={`${config.api.streamS3}/LDB/Mac/SmartLock.dmg`}
                  >
                    <GrApple size={35} />
                  </a>
                </div>
                <div className="name p-3">
                  <b className="p-2" centered>
                    macOS Installer
                  </b>
                  <br></br>
                </div>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <div className="item">
                <div className="p-0">
                  <a
                    className="d-flex justify-content-center btn badge-light"
                    onClick={informationModal}
                    href={`${config.api.streamS3}/LDB/Windows/SmartLock.msi`}
                  >
                    <BsWindows size={35} />
                  </a>
                </div>
                <div className="name p-3">
                  <b centered>Windows Installer</b>
                  <br></br>
                </div>
              </div>
              <div className=" ml-auto p-0">
                <a
                  className="d-flex justify-content-center btn badge-light"
                  href={`SmartLock://${examCheckLink}`}
                >
                  {" "}
                  <FaChevronCircleRight size={35} />
                </a>

                <div className="name p-3">
                  <b centered>{i18n.t("Continue Exam")}</b>
                  <br></br>
                </div>
              </div>
            </div>

            {showFinishExam && (
              <Modal
                size="xl"
                centered
                show={showFinishExam}
                onHide={handleCloseFinishModal}
              >
                <Modal.Body>
                  <div className="m-auto currentSetupContent">
                    <div
                      className="container"
                      style={{ paddingTop: 20, paddingBottom: 20 }}
                    >
                      <div className="terms-condition-panel-smartlock-modal">
                        <div className="text-center font-weight-bold font-size-xlarge">
                          SmartLock Setup for Windows
                          {
                            //i18n.t("")
                          }
                        </div>
                        <br></br>
                        <p className="font-italic text-center font-weight-bold font-size-large">
                          After the SmartLock download process is finished,
                          follow the steps below.
                          {
                            //i18n.t("")
                          }
                        </p>
                        <br></br>
                        <ul className=" font-size-large">
                          <li>
                            Open the downloaded SmartLock.msi installer file by
                            double-clicking it.
                            {
                              //i18n.t("")
                            }
                          </li>
                          <br></br>
                          <li>
                            Once opened, click the 'Next' button to start the
                            installation process.
                            {
                              //i18n.t("")
                            }
                          </li>
                          <br></br>
                          <li>
                            Select the file path on the installation screen and
                            click the 'Next' button.
                            {
                              //i18n.t("")
                            }
                          </li>
                          <br></br>
                          <li>
                            Confirm the installation process by clicking the
                            'Next' button. SmartLock will then automatically
                            install.
                            {
                              //i18n.t("")
                            }
                          </li>
                        </ul>

                        <div
                          className="d-flex flex-row justify-content-end"
                          style={{ margin: 10 }}
                        >
                          <button
                            className="btn buttons fontbold"
                            style={{ border: "1px solid", borderRadius: 10 }}
                            onClick={handleCloseFinishModal}
                          >
                            {i18n.t("Close")}
                          </button>
                        </div>

                        <br />
                      </div>
                    </div>
                  </div>

                  {
                    //i18n.t("By_pressing_Yes_you_will_be_completing_your_mobile_session")
                  }
                </Modal.Body>
              </Modal>
            )}

            {showFinishMacExam && (
              <Modal
                size="xl"
                centered
                show={showFinishMacExam}
                onHide={handleCloseFinishMacModal}
              >
                <Modal.Body>
                  <div className="m-auto currentSetupContent">
                    <div
                      className="container"
                      style={{ paddingTop: 20, paddingBottom: 20 }}
                    >
                      <div className="terms-condition-panel-smartlockmac-modal">
                        <div className="text-center font-weight-bold font-size-xlarge">
                          SmartLock Setup For Mac
                          {
                            //i18n.t("")
                          }
                        </div>
                        <br></br>
                        <p className="font-italic text-center font-weight-bold font-size-large">
                          After the SmartLock download process is finished,
                          follow the steps below.
                          {
                            //i18n.t("")
                          }
                        </p>
                        <br></br>
                        <ul className="align-items-center font-size-large ">
                          <li>
                            Open the downloaded SmartLock.dmg file by
                            double-clicking it.
                            {
                              //i18n.t("")
                            }
                          </li>
                          <br></br>
                          <li>
                            The SmartLock dmg file will contain an applications
                            folder and a SmartLock.app file.
                            {
                              //i18n.t("")
                            }
                          </li>
                          <br></br>
                          <li>
                            Drag and drop the SmartLock.app file into the
                            applications folder.
                            {
                              //i18n.t("")
                            }
                          </li>
                        </ul>

                        <div
                          className="d-flex flex-row justify-content-end"
                          style={{ margin: 10 }}
                        >
                          <button
                            className="btn buttons fontbold"
                            style={{ border: "1px solid", borderRadius: 10 }}
                            onClick={handleCloseFinishMacModal}
                          >
                            {i18n.t("Close")}
                          </button>
                        </div>

                        <br />
                      </div>
                    </div>
                  </div>

                  {
                    //i18n.t("By_pressing_Yes_you_will_be_completing_your_mobile_session")
                  }
                </Modal.Body>
              </Modal>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default withNamespaces()(DownloadScreen);
