import React, { useEffect } from "react";
import i18n from "../../config/i18n";
import { withNamespaces } from "react-i18next";

function ExamReadyScreen() {

  useEffect(() => {
    let array = [1, 2]
    array.map(async item => {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      stream.getTracks().forEach(function (track) {
        track.stop();
      });
    });
  }, []);

  return (
    <div className="d-flex flex-column justify-content-start align-items-center h-100 currentSetupContent">
      <div className="col-md-8 d-flex justify-content-center align-items-center h-100 p-5 mt-5">
        <div
          className="d-flex justify-content-start align-items-center "
          style={{
            backgroundColor: "#29377e",
            color: "white",
            borderRadius: 20,
            height: "50%",
          }}
        >
          <div style={{ fontSize: 20, padding: 50 }}>
            <h4>
              {i18n.t("You_have_successfully_completed_the_pre_exam_system_check_steps")}
              
              
            </h4>
            <h5 className="d-flex justify-content-center mt-5">
              {i18n.t("You_can_now_close_all_of_your_exam_related_tabs_to_complete_your_setup")}
              
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withNamespaces()(ExamReadyScreen)