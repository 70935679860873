import React, { useEffect, useContext } from "react";
import config from "../../config/awsconfig.json";
import axios from "axios";
import { awscontext } from "../../app/awscontext";
import i18n from "../../config/i18n";
import { withNamespaces } from "react-i18next";

function ExamFinishedScreen({ information }) {
  const { sessionList } = useContext(awscontext);

  useEffect(() => {
    const data = {
      instituteId: information.institute.instituteId,
      sessionId: `${information.session.sessionId}${information.student.studentId}`,
      isFinished: true,
    };
    axios.post(
      `${config.api.invokeUrl}${config.openvidu.kms.endpoints.session.context}${config.openvidu.kms.endpoints.session.close.context}`,
      data
    );

    for (const session in sessionList) {
      if(sessionList[session].sessionId.includes("mobile")){
        sessionList[session].signal({
          type: 'examFinished'
        })
        .then(() => {
            console.log('examFinished Message successfully sent', session);
        })
        .catch(error => {
            console.error(error);
        });
      }
      sessionList[session].disconnect();
    }

    let array = [1, 2]
    array.map(async item => {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      stream.getTracks().forEach(function (track) {
        track.stop();
      });
    });
  }, []);

  return (
    <div className="d-flex flex-column justify-content-start align-items-center h-100 currentSetupContent">
      <div className="col-md-8 d-flex justify-content-center align-items-center h-100 p-5 mt-5">
        <div
          className="d-flex justify-content-start align-items-center "
          style={{
            backgroundColor: "#29377e",
            color: "white",
            borderRadius: 20,
            height: "50%",
          }}
        >
          <div style={{ fontSize: 20, padding: 50 }}>
            <h4>
              {i18n.t("You_have_successfully_completed_the_exam_We_hope_everything_wentwell_Good_luck_with_the_assessments")}
            </h4>
            {
              information.session.useLdb === false ?
                <h5 className="d-flex justify-content-center mt-5">
                  {i18n.t("If_SmartLock_is_not_used_in_the_exam")}
                </h5> :
                <h5 className="d-flex justify-content-center mt-5">
                  {i18n.t("If_SmartLock_is_used_in_the_exam")} &nbsp;&nbsp;
                  <img height={30} width={30} src={process.env.PUBLIC_URL + '/close.jpg'} alt={""} />
                </h5>
            }


          </div>
        </div>
      </div>
    </div>
  );
}
export default withNamespaces()(ExamFinishedScreen)
