import React, { useEffect, useState } from "react";
import { FaChevronCircleRight } from "react-icons/fa";
import i18n from "../../config/i18n";
import { withNamespaces } from "react-i18next";
const theme = require("../../config/theme.json");

function TermsOfAgreementScreen(props) {
  const [enteranceConfirm, setEnteranceConfirm] = useState(false);
  const changeCheckboxHandler = () => {
    const button = document.querySelector("button");
    if (document.querySelector("input").checked) {
      button.style.display = "block";
    } else {
      button.style.display = "none";
    }
  };

  useEffect(() => {
    if (props.secondEnterance) {
      setEnteranceConfirm(true);
    }
  }, [props.secondEnterance])

  const changeCheckboxHandlerFromDiv = () => {
    const input = document.querySelector("input");
    input.checked = !input.checked;
    changeCheckboxHandler();
  };

  const buttonHandler = async () => {
    props.setPageState(2);
  };
  const continueHandler = () => {
    setEnteranceConfirm(!enteranceConfirm)
  }
  const secondRender = () => <div className="m-auto currentSetupContent">
    <div className="container" style={{ paddingTop: 30 }}>
      <p className="font-size-medium text-center">You are entering preexam system check more than one time.</p>
      <p className="font-size-medium text-center">If you dont want to continue please use exam link not preexam system check link</p>
      <p className="font-size-medium text-center">Do you want to continue presystem check?</p>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <button
          className={theme.btn_save_small}
          onClick={continueHandler}
        >
          <FaChevronCircleRight size={25} style={{ marginRight: 10 }} />
          {i18n.t("Continue")}
        </button>
      </div>

    </div>

  </div>

  return (enteranceConfirm ? secondRender() :
    <div className="m-auto currentSetupContent">
      <div className="container" style={{ paddingTop: 30 }}>
        <div className="terms-condition-panel">
          <div className="text-center font-weight-bold font-size-xlarge">
            {i18n.t("SmarTest_Invigilate_Terms_&_Conditions")}
          </div>
          <p className="font-italic text-center font-size-small">
            {i18n.t("Please_go_through_the_terms_listed_below")}
          </p>
          <ol>
            <li
              className="font-weight-bold font-size-large"
              style={{ marginTop: 10 }}
            >
              {i18n.t("Test_Taker_Requirements")}
            </li>
            <p
              className="font-italic font-size-small"
              style={{ marginTop: 10 }}
            >
              {i18n.t("In_order_to_take_this_test")}
            </p>
            <ul className="font-size-medium">
              <li>
                {i18n.t("The_Test_Taker_agrees_not_to_share_her_his_unique_test_link_with_any_third_party")}
              </li>
              <li>
                {i18n.t("The_Test_Taker_must_ensure_the_room_is_well_lit_at_all_times")}
              </li>
              <li>
                {i18n.t("The_Test_Taker_must_ensure_there_is_no_noise")}
              </li>
              <li>
                {i18n.t("The_Test_Taker_should_clearly_show_valid_identification_document")}
              </li>
              <li>
                {i18n.t("The_Test_Taker_should_ensure_that_his_her_face_is_clearly")}
              </li>
              <li>
                {i18n.t("The_Test_Taker_must_ensure_there_is_no_intrusion")}
              </li>
              <li>
                {i18n.t("The_Test_Taker_must_ensure_all_system_requirements_are_achieved_for_the_test")}
              </li>
              <li>
                {i18n.t("The_Test_Taker_agrees_that_the_individual_whose_ID_and_image")}
              </li>
              <li>
                {i18n.t("The_Test_Taker_must_remain_within_the_visible")}
              </li>
              <li>
                {i18n.t("The_Test_Taker_must_ensure_the_microphone")}
              </li>
              <li>
                {i18n.t("The_Test_Taker_agrees_to_use_only_one_visual_device_monitor_or_TV")}
              </li>
              <li>

                {i18n.t("The_Test_Taker_is_prohibited_from")}:
                <ul>
                  <li>{i18n.t("any_communication_with_third_parties")}</li>
                  <li>
                    {i18n.t("using_any_unauthorised_materials")}
                  </li>
                  <li>
                    {i18n.t("using_any_communication_or_computing_devices")}
                  </li>
                </ul>
              </li>
              <li>
                {i18n.t("The_Test_Taker_accepts_to_be_recorded")}
              </li>
            </ul>
            <li
              className="font-weight-bold font-size-large"
              style={{ marginTop: 10 }}
            >
              {i18n.t("System_Requirements")}
            </li>
            <p
              className="font-italic font-size-small"
              style={{ marginTop: 10 }}
            >
              {i18n.t("It_is_the_sole_responsibility_of_the_Test_Taker_to_ensure_that_all")}
            </p>
            <ul className="font-size-normal">
              <li>
                {i18n.t("Operating_System_Requirements")}
              </li>
              <li>{i18n.t("Google_Chrome_web_browser")}</li>
              <li>
                {i18n.t("Functioning_and_active_webcam")}
              </li>
              <li>
                {i18n.t("Functioning_and_active_microphone")}
              </li>
              <li>
                {i18n.t("Stable_and_secure_internet_connection")}
              </li>
              <li>
                {i18n.t("Secure_Browser_SmartLock")}
              </li>
              <li>
                {i18n.t("Up_to_150_MB_permanent_space_available_on_the_hard_drive_to_install_SmartLock")}
              </li>
            </ul>
          </ol>

          <div
            className="d-flex flex-row justify-content-between"
            style={{ margin: 10 }}
          >
            <div className="d-flex flex-row align-items-center text-align-center">
              <input
                type="checkbox"
                defaultChecked={false}
                onChange={changeCheckboxHandler}
              />
              <div className="p-2" onClick={changeCheckboxHandlerFromDiv}>
                {i18n.t("I_agree_to_the_terms_for_using_this_remote_proctoring_system")}
              </div>
            </div>
            <button
              className={theme.btn_save_small}
              onClick={buttonHandler}
              style={{
                display: "none",
              }}
            >
              <FaChevronCircleRight size={25} style={{ marginRight: 10 }} />
              {i18n.t("Continue")}
            </button>
          </div>
          <br />
        </div>
      </div>
    </div>
  );
}
export default withNamespaces()(TermsOfAgreementScreen);
