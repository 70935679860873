/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import SystemCheckItem from "./SystemCheckItem";
import axios from "axios";
import config from "../../../config/awsconfig.json";
// import { FaMobile } from "react-icons/fa";
import { awscontext } from "../../awscontext";
import QRCode from "react-qr-code";
import OpenViduConnector from "../../../utils/OpenViduConnector";
import i18n from "../../../config/i18n";
import { withNamespaces } from "react-i18next";
import awsconfig from "../../../config/awsconfig.json";

function CheckMobileConnection(props) {

  const {
    sessionList,
    setSessionList
  } = useContext(awscontext);

  const constructQrValue = () => {
    const data = {};
    data.type = "RPS";
    data.api = {
      origin: `${awsconfig.api.base_url}/mobile/${props.information.institute.instituteId}/${props.information.session.sessionId}/${props.information.student.studentId}/${props.renderIndex}`,
    };
    data.institute = {
      instituteId: props.information.institute.instituteId,
    };
    data.session = {
      sessionId: props.information.session.sessionId,
      startDateTimeUnix: props.information.session.startDateTimeUnix,
      lastStartDateTimeUnix: props.information.session.lastStartDateTimeUnix,
      endDateTimeUnix: props.information.session.endDateTimeUnix
    };
    data.student = {
      studentId: props.information.student.studentId,
      name: props.information.student.name,
      lastname: props.information.student.lastname,
      email: props.information.student.email,
    };
    data.renderIndex = props.renderIndex;
    return JSON.stringify(data);
  };
  const [info, setInfo] = useState({
    title: i18n.t("Checking_Mobile_Camera"),
    ready: i18n.t("WAITING"),
    waitMessage:
      i18n.t("Scan the QR code with your Mobile Phone to connect the second camera"),
    infoCover: "col-6",
    description: i18n.t("This_step_allows_you_to_connect_the_secondary_mobile_camera_to_your_exam"),
    descriptionCover: "col-6",
    buttonText: i18n.t("Next_Step"),
    repeatButtonText: "NONE",
  });
  const information = props.information;
  const meetingId = `${information.session.sessionId}${information.student.studentId}`;
  const username = `${information.student.name} ${information.student.lastname}`;
  const [mobileSettings, setMobileSettings] = useState({
    active: true,
    session: null,
  });

  useEffect(() => {
    const constructOpenViduRecordingSessionOverLambda = async (
      role,
      source
    ) => {
      const openviduConnector = new OpenViduConnector();
      const sessionProperties = {
        customSessionId: `${meetingId}-${source}`,
      };
      await openviduConnector.createSession(sessionProperties);
      const session = openviduConnector.initializeSession();
      let tempSessionList = sessionList;
      tempSessionList[source] = session;
      setSessionList(tempSessionList);
      const connectionProperties = {
        role,
      };
      const connection = await openviduConnector.connectToSession(
        sessionProperties.customSessionId,
        connectionProperties
      );
      await openviduConnector.joinSession(
        session,
        connection,
        username,
        source
      );
      const meetingInformation = {
        meetingId: sessionProperties.customSessionId,
        instituteId: information.institute.instituteId,
        archive: false,
        sessionId: information.session.sessionId,
        latestActiveTime: new Date().getTime(),
        studentId: information.student.studentId,
        state: "ACTIVE",
        startTime: new Date().getTime(),
      };
      session.on("recordingStarted", (event) => {
        meetingInformation.startTime = new Date().getTime();
        updateMeetingInformation(meetingInformation);
        setMobileSettings({
          active: true,
          session,
          recorder: null,
        });
        setInfo((info) => ({
          ...info,
          ready: "OK",
          waitMessage: i18n.t("Your_Mobile_Phone_camera_has_been_connected_successfully"),
        }));
      });
      const updateMeetingInformation = async (meeting) => {
        try {
          await axios.post(
            `${config.api.invokeUrl}${config.aws.gateway.endpoints.meetings.context}`,
            meeting
          );
          console.log("Meeting has successfully updated");
        } catch (err) {
          throw err;
        }
      };

      // session.on("signal:streamingContinue", (event) => {
      //   console.log("Stream devam ediyor");
      //   setInfo((info) => ({
      //     ...info,
      //     ready: "OK",
      //     waitMessage: i18n.t("Your_Mobile_Phone_camera_has_been_connected_successfully"),
      //   }));
      // })

      session.on("connectionCreated", (event) => {
        if (event.target.sessionId === sessionProperties.customSessionId &&
          !event.connection.data.includes("Super Admin") &&
          !event.connection.data.includes("Proctor")) {
          setMobileSettings({
            active: true,
            session,
            recorder: null,
          });
          setInfo((info) => ({
            ...info,
            ready: "OK",
            waitMessage: i18n.t("Your_Mobile_Phone_camera_has_been_connected_successfully"),
          }));
        }
      });
    };

    const configureMeetingSession = async () => {
      await constructOpenViduRecordingSessionOverLambda(
        "SUBSCRIBER",
        `mobile_${props.renderIndex}`
      );
    };
    configureMeetingSession();
  }, []);

  const buttonHandler = () => {
    props.setSettings((settings) => ({
      ...settings,
      [`mobile_${props.renderIndex}`]: mobileSettings,
    }));
    props.buttonHandler();
  };

  return (
    <SystemCheckItem
      Icon={
        <QRCode className="h-100" value={constructQrValue()} />
        // <FaMobile size={200} color={info.ready === "OK" ? "#29377e" : "gray"} />
      }
      info={info}
      buttonHandler={buttonHandler}
      next={props.next}
      prev={props.prev}
      configuration={props.configuration}
      currentStep={props.currentStep}
    />
  );
}
export default withNamespaces()(CheckMobileConnection)