/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { store } from "react-notifications-component";
import i18n from "../../config/i18n";
import { withNamespaces } from "react-i18next";
import { awscontext } from "../awscontext";
import { Modal } from "react-bootstrap";
const config = require("./../../config/awsconfig.json");

function SystemCheck(props) {
  const [currentStep, setCurrentStep] = useState(0);
  const [count, setCount] = useState(0);
  const information = props.information;
  const configuration = props.configuration;
  const [currentElement, setCurrentElement] = useState(
    configuration[currentStep]
  );


  const [currentInPageState, setCurrentInPageState] = useState(0);
  const context = useContext(awscontext);
  const [show, setShow] = useState(false);
  const [pass, setPass] = useState(true);

  const{mobileCameraUsage}=information.session.systemCheck;

  useEffect(() => {
// console.log("deneme",mobileCameraUsage);
    const createSocketListener = (name) => {
      props.settings.pc.screenShare.session.on(name, (event) => {
        const data = JSON.parse(event.data);
        if (event.from.role !== "PUBLISHER") {
          if (data.status === "APPROVED") {
            if (data.type === "ENVIRONMENT_SCAN") {
              if (data.pageState === 3) {
                buttonHandler(data.type, data.pageState);
              } else {
                setCurrentInPageState(data.pageState + 1);
              }
            } else if (data.type === "MATERIAL_SCAN") {
              buttonHandler(data.type, data.pageState);
            } else if (data.type === "MATERIAL_SCAN") {
              buttonHandler(data.type, data.pageState);
            } else if (data.type === "DESTROY_MATERIAL_SCAN") {
              buttonHandler(data.type, data.pageState);
            }
            store.addNotification({
              title: null,
              message: i18n.t("Proctor_approved_your_request"),
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 3500,
                onScreen: true,
              },
            });
          } else if (data.status === "DENIED") {
            store.addNotification({
              title: null,
              message:
                i18n.t("Proctor_denied_your_request_please_re_do_and_send_again"),
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 3500,
                onScreen: true,
              },
            });
            setTimeout(() => {
              sendProctorSignalMessage(data.type, data.text, data.pageState);
            }, 2000);
          }
        }
      });
    };
    if (
      information.session.onboardingProcess === "PROCTOR_ASSISTED" &&
      props.content !== "PREEXAM" &&
      (props.pageState === 4 || props.pageState === 6)
    ) {
      createSocketListener(`signal:proctor_${props.pageState}`);
    }
  }, []);

  useEffect(() => {
    setCurrentElement(configuration[currentStep]);
  }, [currentStep]);

  useEffect(() => {
    const handleBeforeUnload = (ev) => {
      ev.preventDefault();
      let changePreSystemCheckInfo = true;
      let preSystemCheck = {
        internetConnectionStatus: false,
        numberOfDisplaysValidity: false,
        webcamShareable: false,
        audioShareable: false,
        screenShareable: false,
        preExamRoomScan: false,
        preExamMaterialCheck: false,
        toiletBreakPermission: false,
      };
      if (currentElement.renderName === i18n.t("Internet_Connection_Check")) {
      } else if (currentElement.renderName === i18n.t("Internet_Connection_Check")) {
        preSystemCheck.internetConnectionStatus = true;
      } else if (currentElement.renderName === i18n.t("Webcam_Check")) {
        preSystemCheck.internetConnectionStatus = true;
        preSystemCheck.numberOfDisplaysValidity = true;
      } else if (currentElement.renderName === i18n.t("Audio_Check")) {
        preSystemCheck.internetConnectionStatus = true;
        preSystemCheck.numberOfDisplaysValidity = true;
        preSystemCheck.webcamShareable = true;
      } else if (currentElement.renderName === i18n.t("Screen_Share_Check")) {
        preSystemCheck.internetConnectionStatus = true;
        preSystemCheck.numberOfDisplaysValidity = true;
        preSystemCheck.webcamShareable = true;
        preSystemCheck.audioShareable = true;
      } else if (currentElement.renderName === i18n.t("Photo_&_ID_Verification")) {
        preSystemCheck.internetConnectionStatus = true;
        preSystemCheck.numberOfDisplaysValidity = true;
        preSystemCheck.webcamShareable = true;
        preSystemCheck.audioShareable = true;
        preSystemCheck.screenShareable = true;
      } else if (currentElement.renderName === i18n.t("Exam_Environment_Check")) {
        preSystemCheck.internetConnectionStatus = true;
        preSystemCheck.numberOfDisplaysValidity = true;
        preSystemCheck.webcamShareable = true;
        preSystemCheck.audioShareable = true;
        preSystemCheck.screenShareable = true;
      } else if (currentElement.renderName === i18n.t("Material_Check")) {
        preSystemCheck.internetConnectionStatus = true;
        preSystemCheck.numberOfDisplaysValidity = true;
        preSystemCheck.webcamShareable = true;
        preSystemCheck.audioShareable = true;
        preSystemCheck.screenShareable = true;
        preSystemCheck.preExamRoomScan = true;
      } else if (currentElement.renderName === i18n.t("Toilet_Break_Check")) {
        preSystemCheck.internetConnectionStatus = true;
        preSystemCheck.numberOfDisplaysValidity = true;
        preSystemCheck.webcamShareable = true;
        preSystemCheck.audioShareable = true;
        preSystemCheck.screenShareable = true;
        preSystemCheck.preExamRoomScan = true;
        preSystemCheck.preExamMaterialCheck = true;
      } else {
        changePreSystemCheckInfo = false;
      }
      if (changePreSystemCheckInfo) {
        const params = {
          studentId: information.student.studentId,
          preSystemCheck,
          section: "preSystemCheck",
        };
        axios.patch(
          `${config.api.invokeUrl}/institutes/${information.institute.instituteId}/sessions/${information.session.sessionId}`,
          params
        );
      }

      return (ev.returnValue = i18n.t("Are_you_sure_you_want_to_close"));
    };
    if (props.content === "PREEXAM" && !information.test) {
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () =>
        window.removeEventListener("beforeunload", handleBeforeUnload);
    }
  }, [currentStep]);

  const sendProctorSignalMessage = (type, text, state) => {
    const data = {
      text: text,
      type: type,
      pageState: state,
      mainState: props.pageState,
    };
    props.settings.pc.screenShare.session
      .signal({
        data: JSON.stringify(data),
        type: `proctor_${props.pageState}`,
      })
      .then(() => {
        // console.log("MESSAGE SENT");
      })
      .catch((error) => {
        console.error("Err:", error);
      });
  };

  const setChatSession = (session) => {
    props.setChatSession(session);
  };

  const handleInPageState = () => {
    setCurrentInPageState(currentInPageState + 1);
  };

  const modalHandler = () => {
    setShow(false);
    buttonHandler();
  }

  const buttonHandler = (type, pageState) => {
    if(pass && props.pageState === 4 && context.currentStateContext === 3 && mobileCameraUsage){
      setPass(false);
      setShow(true);
    }
    else if (type === "ENVIRONMENT_SCAN" && pageState === 3) {
      if (props.pageState === 4) {
        setCurrentStep(2);
      } else if (props.pageState === 6) {
        setCurrentStep(1);
      }
      setCurrentInPageState(0);
    } else if (type === "MATERIAL_SCAN" && pageState === 0) {
      setCurrentStep(3);
    } else if (type === "DESTROY_MATERIAL_SCAN" && pageState === 0) {
      if (props.pageState === 4) {
        setCurrentStep(3);
      } else if (props.pageState === 6) {
        nextPageButtonHandler();
      }
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const nextPageButtonHandler = () => {
    if (props.pageState === 4) {
      updatePreSystemCheckInformation();
    }
    props.setPageState(props.pageState + 1);
  };

  const updatePreSystemCheckInformation = async () => {
    if (props.content === "PREEXAM" && !information.test) {
      const preSystemCheck = {
        internetConnectionStatus: true,
        numberOfDisplaysValidity: true,
        webcamShareable: true,
        audioShareable: true,
        screenShareable: true,
        preExamRoomScan: true,
        preExamMaterialCheck: true,
        toiletBreakPermission: true,
      };
      const params = {
        studentId: information.student.studentId,
        preSystemCheck,
        section: "preSystemCheck",
      };
      try {
        await axios.patch(
          `${config.api.invokeUrl}/institutes/${information.institute.instituteId}/sessions/${information.session.sessionId}`,
          params
        );
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleRepeatClick = () => {
    setCount((prevCount) => prevCount + 1);
    setCurrentInPageState(0);
  };

  return (
    <React.Fragment>
        <div
          className="d-flex flex-column justify-content-start align-items-center text-center currentSetupContent"
          key={count + currentElement.renderIndex}
          >
          <currentElement.render
            renderIndex={currentElement.renderIndex}
            count={count + currentElement.renderIndex}
            content={props.content}
            next={currentElement.next}
            prev={currentElement.prev}
            currentStep={currentStep}
            buttonHandler={
              currentStep === configuration.length - 1 
                ? nextPageButtonHandler
                : buttonHandler
            }
            setChatSession={setChatSession}
            information={props.information}
            setPublisher={props.setPublisher}
            setSettings={props.setSettings}
            handleRepeatClick={handleRepeatClick}
            setSelectedDevice={props.setSelectedDevice}
            selectedDevice={props.selectedDevice}
            configuration={props.configuration}
            dependencyReady={props.dependencyReady}
            settings={props.settings}
            playNotificationSound={props.playNotificationSound}
            stopNotificationSound={props.stopNotificationSound}
            currentInPageState={currentInPageState}
            sendProctorSignalMessage={sendProctorSignalMessage}
            handleInPageState={handleInPageState}
            setIncomingCallInformation={props.setIncomingCallInformation}
          // {...props}
          />

<Modal 
show={show}
size="xl"
aria-labelledby="contained-modal-title-vcenter"
centered
>
<Modal.Body>
<div className="m-auto currentSetupContent">
<div className="container" style={{ paddingTop: 20, paddingBottom: 20 }}>
  <div className="terms-condition-panel-smartlockmac-modal">
    <div className="text-center font-weight-bold font-size-xlarge">
      {i18n.t("Please_place_your_mobile_camera_in_a_location")}
    </div>
  </div>
</div>
</div>
<div 
className="d-flex flex-row justify-content-end"
style={{ margin: 10 }}
>
<button 
  className="btn buttons fontbold"
  onClick={modalHandler}
  style={{ border: "1px solid", borderRadius: 10 }}
  >
  {i18n.t("Next")}
</button>
</div>
</Modal.Body>
</Modal>
     
        </div>
    </React.Fragment>
  );
}
export default  withNamespaces()(SystemCheck)
