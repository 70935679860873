import React, { useEffect } from "react";
import config from "../../../config/awsconfig.json";
import i18n from "../../../config/i18n";
import { withNamespaces } from "react-i18next";
const theme = require("../../../config/theme.json");

function Header(props) {
  useEffect(() => {
    defaultHeaderStyling();
    if (props.content === "EXAM") {
      switch (props.step) {
        case 2:
          toggleActiveStep("basic-system-check", theme.toggleActive);
          break;
        case 3:
          toggleActiveStep(
            "streaming-check",
            theme.toggleActive,
            "font-weight-bold"
          );
          break;
        case 4:
          toggleActiveStep(
            "onboarding-process",
            theme.toggleActive,
            "font-weight-bold"
          );
          break;
        case 6:
          toggleActiveStep(
            "post-exam-check",
            theme.toggleActive,
            "font-weight-bold"
          );
          break;
        case 7:
          toggleActiveStep(
            "exam-finished",
            theme.toggleActive,
            "font-weight-bold"
          );
          break;
        default:
          console.log(`Unknown step input has provided: ${props.step}`);
      }
    } else if (props.content === "PREEXAM") {
      switch (props.step) {
        case 2:
          toggleActiveStep(
            "pre-basic-system-check",
            theme.toggleActive,
            "font-weight-bold"
          );
          break;
        case 3:
          toggleActiveStep(
            "pre-streaming-check",
            theme.toggleActive,
            "font-weight-bold"
          );
          break;
        case 4:
          toggleActiveStep(
            "pre-onboarding-process",
            theme.toggleActive,
            "font-weight-bold"
          );
          break;
        case 6:
          toggleActiveStep(
            "pre-post-exam-check",
            theme.toggleActive,
            "font-weight-bold"
          );
          break;
        case 7:
          toggleActiveStep(
            "pre-exam-finished",
            theme.toggleActive,
            "font-weight-bold"
          );
          break;
        default:
          console.log(`Unknown step input has provided: ${props.step}`);
      }
    }
  }, [props]);

  const toggleActiveStep = (elementId, ...toggleClasses) => {
    for (const toggleClass of toggleClasses) {
      const foundElement = document.getElementById(elementId);
      if (foundElement) {
        foundElement.classList.toggle(toggleClass);
      }
    }
  };

  const defaultHeaderStyling = () => {
    const currentStepElement = document.getElementById("current-step");
    if (currentStepElement) {
      let activeStepsParent = currentStepElement.children;
      activeStepsParent = Array.from(activeStepsParent).filter(
        (activeStep) => activeStep.id.trim() !== ""
      );
      for (const activeStep of activeStepsParent) {
        activeStep.className = "setupStepItem";
      }
    }
  };

  const examPreperationHeaderContent = () => {
    return (
      <div
        id="current-step"
        className="col-md-10 d-flex flex-row justify-content-center align-items-end h-100"
      >
        <div id="basic-system-check"> {i18n.t("System_Check")}</div>
        <div id="streaming-check">{i18n.t("Streaming_Check")}</div>
        <div id="onboarding-process">{i18n.t("Check_in")}</div>
        {/* <div id="photo-upload">Verification</div>
        <div id="start-exam">Start Exam</div> */}
      </div>
    );
  };

  const preExamHeaderContent = () => {
    return (
      <div
        id="current-step"
        className="col-md-10 d-flex flex-row justify-content-center align-items-end h-100"
      >
        <div id="pre-basic-system-check">{i18n.t("System_Check")}</div>
        <div id="pre-streaming-check">{i18n.t("Streaming_Check")}</div>
        <div id="pre-onboarding-process">{i18n.t("Check_in")}</div>
      </div>
    );
  };

  const prePostExamHeaderContent = () => {
    return (
      <div
        id="current-step"
        className="col-md-10 d-flex flex-row justify-content-center align-items-end h-100"
      >
        <div id="pre-post-exam-check">{i18n.t("Post_Exam_Check")}</div>
        <div id="pre-exam-finished">{i18n.t("Finished")}!</div>
      </div>
    );
  };

  const postExamHeaderContent = () => {
    return (
      <div
        id="current-step"
        className="col-md-10 d-flex flex-row justify-content-center align-items-end h-100"
      >
        {props.information.session.systemCheck.postExamRoomScan ||
        props.information.session.systemCheck.postExamMaterialCheck ? (
          <div id="post-exam-check">{i18n.t("Post_Exam_Check")}</div>
        ) : null}

        <div id="exam-finished">{i18n.t("Finished")}!</div>
      </div>
    );
  };

  const RenderExamHeaderContent = () => {
    if (props.content === "EXAM") {
      if (props.step === 6 || props.step === 7) {
        return <React.Fragment>{postExamHeaderContent()}</React.Fragment>;
      } else {
        return (
          <React.Fragment>{examPreperationHeaderContent()}</React.Fragment>
        );
      }
    } else if (props.content === "PREEXAM") {
      if (props.step === 6 || props.step === 7) {
        return <React.Fragment>{prePostExamHeaderContent()}</React.Fragment>;
      } else {
        return <React.Fragment>{preExamHeaderContent()}</React.Fragment>;
      }
    }
    return null;
  };

  return (
    <div className="d-flex flex-row pb-3" style={{ height: 100 }}>
      <div className="col-md-2 d-flex justify-content-center align-items-end m-auto p-0">
        <img
          src={`https://${config.s3.files}.s3.eu-west-1.amazonaws.com/invigilate_logo.png`}
          // height={50}
          width={"100%"}
          alt=""
        />
      </div>
      {props.step !== 1 && props.step !== 7 ? (
        <React.Fragment>
          <div className="ml-auto col-md-9 p-0">
            <RenderExamHeaderContent />
            <div
              className="col-md-10"
              style={{
                marginTop: 20,
                borderBottom: "2px solid rgba(141, 141, 142, 0.7)",
              }}
            ></div>
          </div>
        </React.Fragment>
      ) : null}
    </div>
  );
}
export default withNamespaces()(Header);