/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import Webcam from "react-webcam";
import SystemCheckItem from "./SystemCheckItem";
import { FiChevronsRight } from "react-icons/fi";
import i18n from "../../../config/i18n";
import { withNamespaces } from "react-i18next";

function CheckMaterialDestroyed(props) {
  const webcamRef = useRef(null);
  const onboardingProcess = props.information.session.onboardingProcess;
  const [info, setInfo] = useState({
    title: i18n.t("Destroy Materials"),
    ready: i18n.t("WAITING"),
    waitMessage: i18n.t("Please_wait_until_the_webcam_is_getting_ready"),
    description: (
      <div className="d-flex flex-column" id="descriptionText"></div>
    ),
    descriptionCover: "col-6",
    buttonText: i18n.t("Next_Step"),
  });
  const videoConstraints = {
    width: 1280,
    height: 720,
    deviceId: props.selectedDevice.video.deviceId,
  };

  useEffect(() => {
    checkCurrentCameraState();
    document.getElementById("requirement_panel").style.display = "none";
  }, []);

  useEffect(() => {
    const requirementPanel = document.getElementById("requirement_panel");
    requirementPanel.style.display = "none";
    const webcamPanel = document.getElementById("webcam_div");
    webcamPanel.classList.toggle("fixedHeight");
    webcamPanel.classList.toggle("singleFixedHeight");
  }, []);

  useEffect(() => {
    if (webcamRef.current) {
      if (
        onboardingProcess === "PROCTOR_ASSISTED" &&
        props.content !== "PREEXAM"
      ) {
        props.sendProctorSignalMessage(
          "DESTROY_MATERIAL_SCAN",
          "Destroy Materials",
          0
        );
      } else {
        webcamRef.current.video.oncanplay = () => {
          setInfo((info) => ({
            ...info,
            ready: "OK",
            waitMessage:
              "Please destroy materials that are listed in the description area (right area).",
          }));
        };
      }

      webcamRef.current.video.onsuspend = async () => {
        checkCurrentCameraState();
      };
    }
  }, [webcamRef]);

  const checkCurrentCameraState = async () => {
    let audioState = "HARDWARE_NOT_EXIST";
    try {
      await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: props.selectedDevice.video.deviceId,
        },
      });
      audioState = "OK";
    } catch (err) {
      audioState = "PERMISSION_DENIED";
    }
    if (audioState === "OK") {
    } else if (audioState === "HARDWARE_NOT_EXIST") {
      setInfo((info) => ({
        ...info,
        ready: "FAILED",
        waitMessage:
          i18n.t("No_webcam_detected_Please_plug_connect_a_webcam_and_repeat_the_system_check"),
      }));
    } else if (audioState === "PERMISSION_DENIED") {
      setInfo((info) => ({
        ...info,
        ready: "FAILED",
        waitMessage:
          i18n.t("Webcam_detected_but_the_permissions_are_denied_Please_check_your_Camera_Privacy_Settings"),
      }));
    }
  };

  const RenderRoomScanSubSteps = () => {
    return (
      <React.Fragment>
        <div
          style={{
            color: "rgba(141, 141, 142, 1)",
            fontSize: 14,
            fontWeight: "bold",
            textAlign: "left",
          }}
        >
          {i18n.t("Please_destroy_the_items_listed_below")}
          :
        </div>
        <div
          id="workspace_span"
          style={{
            color: "rgba(141, 141, 142, 0.7)",
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          <FiChevronsRight size={24} className="mr-3" />
        {i18n.t("Scrap_paper")}
        </div>
        {/* <div
          id="over_and_under_check"
          style={{
            color: "rgba(141, 141, 142, 0.7)",
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          <FiChevronsRight size={24} className="mr-3" />
          Pen and pencils
        </div> */}
      </React.Fragment>
    );
  };

  return (
    <SystemCheckItem
      Icon={
        <React.Fragment>
          <div
            className="row h-100 "
            style={{ backgroundColor: "rgba(255,255,255,1)" }}
          >
            <Webcam
              className="h-100"
              audio={false}
              ref={webcamRef}
              videoConstraints={videoConstraints}
            />
          </div>
          <div className="col-md-3 d-flex flex-column justify-content-center align-items-start ml-3">
            {RenderRoomScanSubSteps()}
          </div>
        </React.Fragment>
      }
      info={info}
      buttonHandler={props.buttonHandler}
      next={props.next}
      prev={props.prev}
      handleRepeatClick={props.handleRepeatClick}
      repeatableButton={true}
      configuration={props.configuration}
      currentStep={props.currentStep}
    />
  );
}
export default withNamespaces()(CheckMaterialDestroyed)