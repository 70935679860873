import React, { useState, useEffect, useRef } from "react";
import { FcWebcam } from "react-icons/fc";
import SystemCheckItem from "./SystemCheckItem";
import { Modal, Form, Button } from "react-bootstrap";
import { store } from "react-notifications-component";
import i18n from "../../../config/i18n";
import { withNamespaces } from "react-i18next";
 function CheckWebcam(props) {
  const [info, setInfo] = useState({
    title: i18n.t("Checking_Webcam"),
    ready: i18n.t("WAITING"),
    waitMessage: i18n.t("Please_wait"),
    infoCover: "col-6",
    description:
      i18n.t("This_step_will_check_whether_there_is_a_webcam_connected_to_your_system"),
    descriptionCover: "col-6",
    buttonText: i18n.t("Next_Step"),
  });
  const [deviceList, setDeviceList] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [show, setShow] = useState(false);
  const stream = useRef(null);
  let currentDeviceId = null;

  useEffect(() => {
    const getAllVideoSources = async () => {
      await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      const enumeratedDevices = await navigator.mediaDevices.enumerateDevices();
      setDeviceList(
        enumeratedDevices.filter((device) => device.kind === "videoinput")
      );
      setShow(true);
    };
    getAllVideoSources();
  }, []);

  useEffect(() => {
    const findWebcamSource = async () => {
      let stream;
      let videoState = "HARDWARE_NOT_EXIST";
      try {
        stream = await navigator.mediaDevices.getUserMedia({
          video: {
            deviceId: selectedDevice.deviceId,
          },
        });
        videoState = "OK";
      } catch (err) {
        videoState = "PERMISSION_DENIED";
      }
      if (videoState === "OK") {
        setInfo((info) => ({
          ...info,
          ready: "OK",
          waitMessage: i18n.t("Success_Please_click_Next_Step_to_proceed"),
        }));
      } else if (videoState === "HARDWARE_NOT_EXIST") {
        setInfo((info) => ({
          ...info,
          ready: "FAILED",
          waitMessage:
            i18n.t("No_webcam_detected_Please_plug_connect_a_webcam_and_repeat_the_system_check"),
        }));
      } else if (videoState === "PERMISSION_DENIED") {
        setInfo((info) => ({
          ...info,
          ready: "FAILED",
          waitMessage:
            i18n.t("Webcam_detected_but_the_permissions_are_denied_Please_check_your_Camera_Privacy_Settings"),
        }));
      }
      return stream;
    };
    if (selectedDevice) {
      findWebcamSource().then((data) => {
        stream.current = data;
      });
    }
    return () => {
      if (stream.current) {
        stream.current.getTracks().forEach(function (track) {
          if (track.readyState === "live") {
            track.stop();
          }
        });
      }
    };
  }, [selectedDevice]);

  const handleClose = () => {
    setShow(false);
  };

  const renderVideoInputDeviceList = () => {
    const renderDeviceList = [];
    deviceList.forEach((device, index) => {
      renderDeviceList.push(
        <div
          key={index}
          className="d-flex flex-column text-center"
          style={{
            marginTop: 10,
            marginBottom: 10,
            border: "1px solid #29377e",
            borderRadius: 10,
            padding: 10,
            color: "#29377e",
          }}
        >
          <Form.Check
            name="videoinput"
            type={"radio"}
            id={device.deviceId}
            label={device.label}
          />
        </div>
      );
    });
    return (
      <Form
        onChange={(e) => {
          currentDeviceId = e.target.id;
        }}
      >
        <div key={`video-input-device-form`}>{renderDeviceList}</div>
      </Form>
    );
  };

  const renderModalFooter = () => {
    return (
      <Button
        variant="primary"
        style={{ backgroundColor: "#29377e", borderColor: "#29377e" }}
        onClick={() => {
          if (currentDeviceId) {
            setSelectedDevice(
              deviceList.find((device) => device.deviceId === currentDeviceId)
            );
            setShow(false);
          } else {
            store.addNotification({
              title: null,
              message: i18n.t("Please_select_an_appropriate_webcam_resource"),
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 3500,
                onScreen: true,
              },
            });
          }
        }}
      >
        Select
      </Button>
    );
  };
  const buttonHandler = () => {
    props.setSelectedDevice((prevSelectedDevice) => ({
      ...prevSelectedDevice,
      video: selectedDevice,
    }));
    props.buttonHandler();
  };

  return (
    <React.Fragment>
      <SystemCheckItem
        Icon={
          <FcWebcam // GrWebcam
            size={200}
            color={info.ready === "OK" ? "#29377e" : "gray"}
          />
        }
        info={info}
        buttonHandler={buttonHandler}
        next={props.next}
        prev={props.prev}
        handleRepeatClick={props.handleRepeatClick}
        repeatableButton={true}
        configuration={props.configuration}
        currentStep={props.currentStep}
      />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <div
            className="m-auto text-center"
            style={{ fontWeight: "bold", color: "#29377e" }}
          >
            {i18n.t("Please_select_your_video_source_from_the_list_below")}
          </div>
        </Modal.Header>
        <Modal.Body>{renderVideoInputDeviceList()}</Modal.Body>
        <Modal.Footer>{renderModalFooter()}</Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
export default withNamespaces() (CheckWebcam)
