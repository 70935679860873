/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import LoadingIcon from "../LoadingIcon";
import OpenViduConnector from "../../utils/OpenViduConnector";
import { store } from "react-notifications-component";
import { awscontext } from "../awscontext";
import AwsConfig from "../../config/awsconfig.json";
import translationEN from "../../config/en.json";
import i18n from "../../config/i18n";
import { withNamespaces } from "react-i18next";

const config = require("./../../config/awsconfig.json");
const testconfig = require("../../config/testconfig.json");
const theme = require("../../config/theme.json");

function ExamUrlScreen(props) {
  const [link, setLink] = useState(null);
  const [checkDone, setCheckDone] = useState(null);
  const [pscMessage, setPscMessage] = useState(null);
  const [instituteInf, setInstituteInf] = useState(null);
  const [isLoaded, setIsLoaded] = useState(props.isLoaded || true);
  const [instituteId, setInstituteId] = useState();
  const { setPathDownload, setDefaultWarnings, setIsAIactive, setShowToStudent } = useContext(awscontext);

  useEffect(async () => {
    let linknormal;
    if (props.path !== "/") {
      linknormal = props.path.split("/");
      const instituteId = linknormal[linknormal.length - 3];
      try {
        const res = await axios.get(
          `${config.api.invokeUrl}/institutes/${instituteId}`
        );
        setInstituteInf(res.data);
        return res.data;
      } catch (err) {
        console.log(`An error has occurred: ${err}`);
        return null;
      }
    }
  }, []);

  useEffect(async () => {
    if (link) {
      const linknormal = link.split("/");
      console.log(props.path);
      const instituteId = linknormal[linknormal.length - 3];
      try {
        const res = await axios.get(
          `${config.api.invokeUrl}/institutes/${instituteId}`
        );
        setInstituteInf(res.data);
        return res.data;
      } catch (err) {
        console.log(`An error has occurred: ${err}`);
        return null;
      }
    }
  }, [checkDone]);

  useEffect(() => {
    if (props.path !== "/" && props.path !== "/lockdownBrowser") {
      checkLinkInformation(props.path);
    }
  }, []);

  useEffect(async () => {
    const params = {
      translation: {},
    };
    if (instituteInf) {

      let defaulthKey = "en";
      let key = localStorage.getItem("i18nextLng");
      if (key === null) {
        key = "en";
      }
      if (defaulthKey === null) {
        defaulthKey = "en";
      }

      try {
        await fetch(`${AwsConfig.api.s3}/assets/languages/${defaulthKey}.json`)
          .then((res) => res.json())
          .then((data) => {
            params.translation = data;
            localStorage.setItem("state", JSON.stringify(params));
            i18n.changeLanguage(defaulthKey);
            localStorage.setItem("lang", localStorage.getItem("i18nextLng"));
          });
      } catch {
        params.translation = translationEN;
        localStorage.setItem("state", JSON.stringify(params));
        i18n.changeLanguage("en");
        localStorage.setItem("lang", "en");
        localStorage.setItem("i18nextLng", "en");
      }
    } else {
      params.translation = translationEN;
      await localStorage.setItem("state", JSON.stringify(params));
      i18n.changeLanguage("en");
      localStorage.setItem("lang", "en");
      localStorage.setItem("i18nextLng", "en");
    }
  }, [instituteInf]);

  useEffect(async () => {
    try {
      let result = await axios.get(
        `${config.api.invokeUrl}/institutes/${instituteId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );

      if (result.data) {
        const { defaultWarning, warnings } = result.data;
        result.data.defaultWarning && setDefaultWarnings(warnings[defaultWarning]);
      }
    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  }, [instituteId])

  const checkLinkInformation = async (value) => {
    try {
      if (!value) {
        value = link;
      }

      setCheckDone(true);
      const linkParts = value.split("/");
      const studentId = linkParts[linkParts.length - 1];
      const sessionId = linkParts[linkParts.length - 2];
      const instituteId = linkParts[linkParts.length - 3];
      const rxHashCode = linkParts[linkParts.length - 4];

      const data = {
        instituteId: instituteId,
        sessionId: `${sessionId}${studentId}`,
        isWeb: true,
      };
      axios.post(
        `${config.api.invokeUrl}${config.openvidu.kms.endpoints.session.context}${config.openvidu.kms.endpoints.session.close.context}`,
        data
      );

      instituteId && setInstituteId(instituteId)

      if (
        instituteId === testconfig.institute.instituteId &&
        sessionId === testconfig.session.sessionId &&
        studentId === testconfig.student.studentId
      ) {
        props.setInformation(testconfig);
        props.setPageState(1);
      } else {
        setIsLoaded(false);
        const sessionInformation = await fetchSessionInformation(
          sessionId,
          studentId,
          instituteId
        );

        const { isAIactive } = sessionInformation.foundSession;
        setShowToStudent(sessionInformation.foundSession.showToStudent);
        setIsAIactive(isAIactive);

        if (
          sessionInformation &&
          sessionInformation.foundSession &&
          sessionInformation.foundStudent
        ) {
          const tawkToAtrributes = {
            name: `${sessionInformation.foundStudent.name} ${sessionInformation.foundStudent.lastname}`,
            email: `${sessionInformation.foundStudent.email}`,
          };
          const currentDateTimeUnix = new Date().getTime();
          const lastStartDateTimeUnix = sessionInformation.foundSession.lastStartDateTimeUnix;
          const startTimeUnix = sessionInformation.foundSession.startDateTimeUnix;
          if (props.content === "EXAM") {
            if (
              sessionInformation.foundSession.useLdb &&
              rxHashCode !== "lockdownBrowser"
            ) {
              setPathDownload(value);
              props.setPageState(8);
            } else if (
              sessionInformation.foundSession.instanceState === "CONFIGURABLE"
            ) {
              store.addNotification({
                title: i18n.t("Session_not_started_yet"),
                message: i18n.t(
                  "The_session_has_not_started_yet_please_try_again_in_15_minutes_before_the_exam_start_time"
                ),
                type: "info",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 3500,
                  onScreen: true,
                },
              });

              setIsLoaded(true)
            } else if (
              sessionInformation.foundSession.instanceState === "INITIALIZING"
            ) {
              store.addNotification({
                title: i18n.t("Environment_not_ready"),
                message: i18n.t(
                  "Please_wait_while_we_prepare_your_testing_environment"
                ),
                type: "info",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 3500,
                  onScreen: true,
                },
              });
              setIsLoaded(true)
            } else if (
              sessionInformation.foundStudent.systemCheckLinkStatus ===
              "INACTIVE"
            ) {
              const meetingId = `${sessionId}${studentId}-webcam`;
              const meetingInformation = await fetchMeetingInformation(
                meetingId,
                instituteId
              );

              if (meetingInformation.state === "KICKED") {
                store.addNotification({
                  title: null,
                  message: i18n.t("The_Proctor_disqualified_you_from_the_exam"),
                  type: "danger",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animate__animated", "animate__fadeIn"],
                  animationOut: ["animate__animated", "animate__fadeOut"],
                  dismiss: {
                    duration: 3500,
                    onScreen: true,
                  },
                });
              } else {
                if (sessionInformation?.foundStudent?.isFinishedExam) {
                  store.addNotification({
                    title: i18n.t("Already finished your exam"),
                    message: i18n.t("You already have finished your exam. So you cannot reenter the same exam. If you have any problems, please contact with your Proctor"),
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 3500,
                      onScreen: true,
                    },
                  });
                  setIsLoaded(true);
                } else {
                  setTawkToUser(
                    tawkToAtrributes,
                    `${sessionId}${studentId}-technical`
                  );
                  props.setInformation({
                    institute: {
                      instituteId,
                    },
                    session: sessionInformation.foundSession,
                    student: sessionInformation.foundStudent,
                  });

                  setIsLoaded(true);
                  props.setSuccessfulReconnection(true);
                  if (link) {
                    if (!link.includes("preExamSystemCheck")) {
                      props.setPageState(1);
                    } else {
                      setPscMessage(true);
                    }
                  } else {
                    props.setPageState(1);
                  }
                }
              }
            } else if (currentDateTimeUnix > lastStartDateTimeUnix) {
              store.addNotification({
                title: i18n.t("Last_Start_Time_Exceeded"),
                message:
                  i18n.t(
                    "You_cannot_attend_this_session_because_the_last_start_time_was_set_as"
                  ) + ` ${new Date(lastStartDateTimeUnix).toLocaleString()}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 3500,
                  onScreen: true,
                },
              });
              setIsLoaded(true);
            } else if (
              sessionInformation.foundStudent.systemCheckLinkStatus === "ACTIVE"
            ) {
              try {
                const openviduConnector = new OpenViduConnector();
                const result = await openviduConnector.checkSession(
                  `${sessionInformation.foundSession.sessionId}${sessionInformation.foundStudent.studentId}`
                );
                if (result.data === "RUNNING") {
                  setTawkToUser(
                    tawkToAtrributes,
                    `${sessionInformation.foundSession.sessionId}${sessionInformation.foundStudent.studentId}-technical`
                  );
                  props.setInformation({
                    institute: {
                      instituteId,
                    },
                    session: sessionInformation.foundSession,
                    student: sessionInformation.foundStudent,
                  });
                  if (currentDateTimeUnix < startTimeUnix) {
                    store.addNotification({
                      title: i18n.t("Session_not_started_yet"),
                      message: i18n.t("Exam_not_started_yet"),
                      type: "info",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animate__animated", "animate__fadeIn"],
                      animationOut: ["animate__animated", "animate__fadeOut"],
                      dismiss: {
                        duration: 3500,
                        onScreen: true,
                      },
                    });

                    setIsLoaded(true);
                  } else {
                    setIsLoaded(true);
                    if (link) {
                      if (!link.includes("preExamSystemCheck")) {
                        props.setPageState(1);
                      } else {
                        setPscMessage(true);
                      }
                    } else {
                      props.setPageState(1);
                    }
                  }
                } else {
                  store.addNotification({
                    title: i18n.t("Environment_not_ready"),
                    message: i18n.t(
                      "Please_wait_while_we_prepare_your_testing_environment"
                    ),
                    type: "info",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 3500,
                      onScreen: true,
                    },
                  });
                }
              } catch (err) {
                store.addNotification({
                  title: i18n.t("Environment_not_ready"),
                  message: i18n.t(
                    "Please_wait_while_we_prepare_your_testing_environment"
                  ),
                  type: "info",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animate__animated", "animate__fadeIn"],
                  animationOut: ["animate__animated", "animate__fadeOut"],
                  dismiss: {
                    duration: 3500,
                    onScreen: true,
                  },
                });
              }
            }
          } else {
            setTawkToUser(
              tawkToAtrributes,
              `${sessionInformation.foundSession.sessionId}${sessionInformation.foundStudent.studentId}-pre-technical`
            );
            props.setInformation({
              institute: {
                instituteId,
              },
              session: sessionInformation.foundSession,
              student: sessionInformation.foundStudent,
            });
            props.setPageState(1);
          }
        } else {
          store.addNotification({
            title: i18n.t("Student_not_found"),
            message: i18n.t(
              "Student_could_not_found_in_the_provided_session_Please_contact_with_your_Proctor"
            ),
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3500,
              onScreen: true,
            },
          });
          setIsLoaded(true);
        }
      }
    } catch (e) {
      console.log(`Main An error has occurred: ${e}`);
    }
  };

  const setTawkToUser = async (tawkToAtrributes, meetingId) => {
    props.loadTawkTo(config.tawkTo.key, config.tawkTo.secret, tawkToAtrributes);
    props.updateLoginTimestamp(new Date().getTime());
    props.periodicallyGetTechnicalChatMessages(
      meetingId,
      tawkToAtrributes.name
    );
  };

  const fetchSessionInformation = async (sessionId, studentId, instituteId) => {
    try {
      const res = await axios.get(
        `${config.api.invokeUrl}/lives/${sessionId}/student/${studentId}/${instituteId}`
      );
      return res.data;
    } catch (err) {
      console.log(`An error has occurred: ${err}`);
      return null;
    }
  };

  const fetchMeetingInformation = async (meetingId, instituteId) => {
    try {
      const res = await axios.get(
        `${config.api.invokeUrl}/meetings/${meetingId}/${instituteId}`
      );
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const mainContent = () => {
    return (
      <React.Fragment>
        <div className="row" style={{ width: "80%" }}>
          <input
            className="form-control col-md-9"
            type="text"
            id="examUrl"
            size="50"
            onChange={(e) => setLink(e.target.value)}
          />

          <button
            type="submit"
            className={theme.btn_save}
            onClick={() => checkLinkInformation()}
            id="joinExam"
          >
            {i18n.t("Join")}
          </button>

          {pscMessage ? (
            <p>
              {" "}
              {i18n.t("Please_Use_Browser_Directory_for_Presystem_Check")} .
            </p>
          ) : null}
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className="d-flex flex-column m-auto align-items-center justify-content-center currentSetupContent h-75 w-75">
      {isLoaded === true ? mainContent() : <LoadingIcon />}
    </div>
  );
}

export default withNamespaces()(ExamUrlScreen);
