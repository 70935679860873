/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import * as faceapi from "face-api.js";
import { useStorage } from "../hooks/useStorage.js";
import SystemCheckItem from "./system-check-steps/SystemCheckItem";
import ReactLoading from "react-loading";
import { store } from "react-notifications-component";
import { MdOutlineLinkedCamera } from "react-icons/md";
import { CgRepeat } from "react-icons/cg";
import config from "../../config/awsconfig.json";
import i18n from "../../config/i18n";
import { withNamespaces } from "react-i18next";
function SecurityCheck(props) {
  // ID Verification failed. Please plug/connect a webcam and repeat the system check
  const imageRef = useRef();
  const webcamRef = useRef();
  const [pageState, setPageState] = useState(0);
  const intervalId = useRef();
  const [webcamReady, setWebcamReady] = useState(false);
  const sessionId = props.information.session.sessionId;
  const studentId = props.information.student.studentId;
  const onboardingProcess = props.information.session.onboardingProcess;
  // const sessionId = "kttvwrcn";
  // const studentId = "kttvzjgv";
  const [uploadObject] = useStorage(config.s3.streamFiles);
  const [info, setInfo] = useState({
    title: i18n.t("Photo_Verification"),
    ready: "WAITING",
    waitMessage:
      i18n.t("Please_wait_We_are_preparing_the_system_for_your_Photo_ID_Verification"),
    descriptionCover: "col-auto",
    buttonText: i18n.t("Take_a_Photo"),
    buttonIcon: <MdOutlineLinkedCamera size={40} style={{ marginRight: 10 }} />,
    repeatButtonText: i18n.t("Retake_Photo"),
    //repeatButtonIcon: <CgRepeat size={40} style={{ marginRight: 10 }} />,
    repeatButtonDisabled: true,
  });
  const singlePersonOnFrame = useRef(false);
  const betterAspectOfFace = useRef(false);
  const resizedDetections = useRef([]);
  const [detectionsStarted, setDetectionsStarted] = useState(false);
  const [imageSrc, setImageSrc] = useState();
  const [videoWidth, setVideoWidth] = useState();
  const [videoHeight, setVideoHeight] = useState();
  const count = props.count;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  useEffect(() => {
    window.addEventListener('resize', changeWindowSize);
    
    return () => {
      window.removeEventListener('resize', changeWindowSize);
    };

  }, []);

  const changeWindowSize = () => {
    setScreenHeight(window.innerHeight);
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    const requirementPanel = document.getElementById("requirement_panel");
    requirementPanel.style.display = "none";
    const webcamPanel = document.getElementById("webcam_div");
    webcamPanel.classList.toggle("fixedHeight");
    webcamPanel.classList.toggle("singleFixedHeight");
  }, []);

  useEffect(() => {
    if (webcamRef.current) {
      const videoElement = webcamRef.current.video;
      videoElement.oncanplay = () => {
        setInfo((info) => ({
          ...info,
          waitMessage: i18n.t("Please_wait_while_we_initiate_the_webcam_connection"),
        }));
        setVideoWidth(videoElement.offsetWidth);
        setVideoHeight(videoElement.offsetHeight);
        setWebcamReady(true);
      };
    }
  }, [webcamRef.current]);

  useEffect(() => {
    if (videoWidth && videoHeight && count === 0) {
      if (
        onboardingProcess === "PROCTOR_ASSISTED" &&
        props.content !== "PREEXAM"
      ) {
        props.settings.pc.screenShare.session.on(
          "signal:proctor_4",
          (event) => {
            if (event.from.role !== "PUBLISHER") {
              setInfo((info) => ({
                ...info,
                ready: "OK",
              }));
              const data = JSON.parse(event.data);
              if (data.type === "PHOTO_ID") {
                if (data.status === "APPROVED") {
                  if (data.pageState === 1) {
                    uploadObjectToStorage(false).then(() => {
                      setPageState(2);
                    });
                  } else if (data.pageState === 3) {
                    uploadObjectToStorage(true).then(() => {
                      setPageState(4);
                    });
                  }
                } else if (data.status === "DENIED") {
                  setPageState(data.pageState < 2 ? 0 : 2);
                }
              }
            }
          }
        );
      }
    }
  }, [videoWidth, videoHeight]);

  useEffect(() => {
    if (webcamReady && props.dependencyReady && pageState === 0) {
      const video = webcamRef.current.video;
      const canvas = faceapi.createCanvasFromMedia(video);
      canvas.id = "detection_canvas";
      canvas.style.position = "absolute";
      canvas.style.zIndex = 999;
      document.getElementById("webcam_div").append(canvas);
      const displaySize = {
        width: videoWidth,
        height: videoHeight,
      };
      if(document.getElementById("middle_canvas") !== null)
        document.getElementById("middle_canvas").remove();
      if(document.getElementById("left_darken_canvas") !== null)
        document.getElementById("left_darken_canvas").remove();
      if(document.getElementById("top_darken_canvas") !== null)
        document.getElementById("top_darken_canvas").remove();
      if(document.getElementById("bottom_darken_canvas") !== null)
        document.getElementById("bottom_darken_canvas").remove();
      if(document.getElementById("right_darken_canvas") !== null)
        document.getElementById("right_darken_canvas").remove();
      createDarkenBackgroundCanvas();
      if (displaySize.width !== 0 && displaySize.height !== 0) {
        faceapi.matchDimensions(canvas, displaySize);
        const currentIntervalId = setInterval(async () => {
          /*const detections = await faceapi
            .detectAllFaces(video, new faceapi.TinyFaceDetectorOptions())
            .withFaceLandmarks()
            .withFaceExpressions();
            */
          if (detectionsStarted === false) {
            setDetectionsStarted(true);
          }
          /*
          singlePersonOnFrame.current = true;
          betterAspectOfFace.current = false;
          if (detections.length === 1) {
            singlePersonOnFrame.current = false;
            if (detections[0].detection.classScore < 0.6) {
              betterAspectOfFace.current = true;
            }
          }
          
          resizedDetections.current = faceapi.resizeResults(
            detections,
            displaySize
          );
          */
          canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height);
          // faceapi.draw.drawDetections(canvas, resizedDetections.current);
          // faceapi.draw.drawFaceExpressions(canvas, resizedDetections.current);
        }, 100);
        intervalId.current = currentIntervalId;
      }
    }

    return () => {
      clearInterval(intervalId.current);
    };
  }, [webcamReady, props.dependencyReady, pageState]);

  useEffect(() => {
    if (detectionsStarted) {
      setInfo((info) => ({
        ...info,
        ready: "OK",
        waitMessage: i18n.t("Please_take_a_photo_of_yourself_and_click_Next_Step_to_proceed"),
      }));
    }
  }, [detectionsStarted]);

  useEffect(() => {
    if (detectionsStarted) {
      const nextButton = document.getElementById("next_button");
      const nextButtonDiv = document.getElementById("next_button_div");
      const detectionCanvas = document.getElementById("detection_canvas");
      switch (pageState) {
        case 0:
          setInfo((info) => ({
            ...info,
            buttonText: i18n.t("Take_a_Photo"),
            waitMessage: i18n.t("Please_take_a_photo_of_yourself_and_click_Next_Step_to_proceed"),
            buttonIcon: (
              <MdOutlineLinkedCamera size={40} style={{ marginRight: 10 }} />
            ),
            repeatButtonDisabled: true,
          }));
          webcamRef.current.video.style.display = "";
          imageRef.current.style.display = "none";
          break;
        case 1:
          if (
            onboardingProcess === "PROCTOR_ASSISTED" &&
            props.content !== "PREEXAM"
          ) {
            setInfo((info) => ({
              ...info,
              ready: "WAITING",
              waitMessage: i18n.t("Please_wait_for_your_proctors_approval"),
              buttonIcon: null,
              repeatButtonDisabled: false,
            }));
          } else {
            setInfo((info) => ({
              ...info,
              buttonText: i18n.t("Next_Step"),
              buttonIcon: null,
              repeatButtonDisabled: false,
            }));
          }
          // extractFaceFromBox(resizedDetections.current[0].detection.box);
          if (detectionCanvas) {
            detectionCanvas.remove();
          }
          webcamRef.current.video.style.display = "none";
          imageRef.current.style.display = "";
          break;
        case 2:
          setInfo((info) => ({
            ...info,
            buttonText: i18n.t("Take_a_Photo"),
            waitMessage: i18n.t("Please_take_a_photo_of_your_ID_Card_and_click_Next_Step_to_proceed"),
            buttonIcon: (
              <MdOutlineLinkedCamera size={40} style={{ marginRight: 10 }} />
            ),
            repeatButtonDisabled: true,
          }));
          webcamRef.current.video.style.display = "";
          imageRef.current.style.display = "none";
          break;
        case 3:
          if (
            onboardingProcess === "PROCTOR_ASSISTED" &&
            props.content !== "PREEXAM"
          ) {
            setInfo((info) => ({
              ...info,
              ready: "WAITING",
              waitMessage: i18n.t("Please_wait_for_your_proctors_approval"),
              buttonIcon: null,
              repeatButtonDisabled: false,
            }));
          } else {
            setInfo((info) => ({
              ...info,
              buttonText: i18n.t("Next_Step"),
              buttonIcon: null,
              repeatButtonDisabled: false,
            }));
          }
          webcamRef.current.video.style.display = "none";
          imageRef.current.style.display = "";
          break;
        case 4:
          setInfo((info) => ({
            ...info,
            iconCover: "col-md-12",
            descriptionCover: "col-md-0",
          }));

          // repeatableButtonDiv.removeChild(takeAPhoto);
          nextButtonDiv.removeChild(nextButton);
          props.buttonHandler();
          break;
        default:
          console.log("case default behavior");
      }
    }
  }, [detectionsStarted, pageState]); // eslint-disable-next-line default-case

  const handleRepeatButton = () => {
    const currentPageState = pageState - 1;
    if (
      onboardingProcess === "PROCTOR_ASSISTED" &&
      props.content !== "PREEXAM"
    ) {
      if (currentPageState === 0) {
        setInfo((info) => ({
          ...info,
          ready: "OK",
          waitMessage: i18n.t("Please_take_a_photo_of_yourself_and_click_Next_Step_to_proceed"),
        }));
      } else if (currentPageState === 2) {
        setInfo((info) => ({
          ...info,
          ready: "OK",
          waitMessage: i18n.t("Please_take_a_photo_of_your_ID_Card_and_click_Next_Step_to_proceed"),
        }));
      }
    }
    setPageState(currentPageState);
  };

  const showScreenShot = () => {
    if (pageState === 0) {
      if (singlePersonOnFrame.current) {
        store.addNotification({
          title: i18n.t("Taking_photo_not_successful"),
          message: i18n.t("There_must_be_only_one_person_in_the_frame"),
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3500,
            onScreen: true,
          },
        });
      }
      if (betterAspectOfFace.current) {
        store.addNotification({
          title: i18n.t("Taking_photo_not_successful"),
          message: i18n.t("Please_provide_a_better_aspect_of_your_face"),
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3500,
            onScreen: true,
          },
        });
      }

      if (singlePersonOnFrame.current || betterAspectOfFace.current) {
        return;
      }
    }
    const imageSrc = webcamRef.current.getScreenshot();
    setImageSrc(imageSrc);
    if (pageState < 2) {
      if (
        onboardingProcess === "PROCTOR_ASSISTED" &&
        props.content !== "PREEXAM"
      ) {
        props.sendProctorSignalMessage("PHOTO_ID", "Photo", 1);
      }
      setPageState(1);
    } else {
      if (
        onboardingProcess === "PROCTOR_ASSISTED" &&
        props.content !== "PREEXAM"
      ) {
        props.sendProctorSignalMessage("PHOTO_ID", "ID Card", 3);
      }
      setPageState(3);
    }
  };

  const buttonHandler = () => {
    // eslint-disable-next-line default-case
    switch (pageState) {
      case 0:
        showScreenShot();
        break;
      case 1:
        uploadObjectToStorage(false).then(() => {
          setPageState(2);
        });
        break;
      case 2:
        showScreenShot();
        break;
      case 3:
        uploadObjectToStorage(true).then(() => {
          setPageState(4);
        });
        break;
      case 4:
        proceedToExamHandler();
        break;
    }
  };

  const uploadObjectToStorage = async (photoCheck) => {
    let filename;
    if (photoCheck) {
      filename = "idcard.png";
    } else {
      filename = "facephoto.png";
    }
    const file = dataURLtoFile(imageRef.current.src, filename);
    let uploadPath;
    if (props.content === "PREEXAM") {
      uploadPath = `${sessionId}${studentId}/images/preSystemCheck/`;
    } else if (props.content === "EXAM") {
      uploadPath = `${sessionId}${studentId}/images/systemCheck/`;
    }
    uploadObject(file, file.name, uploadPath);
    if (photoCheck) {
      // document.getElementById("middle_canvas").remove();
      // document.getElementById("left_darken_canvas").remove();
      // document.getElementById("top_darken_canvas").remove();
      // document.getElementById("bottom_darken_canvas").remove();
      // document.getElementById("right_darken_canvas").remove();
      setInfo((info) => ({
        ...info,
        waitMessage: i18n.t("Your_exam_will_start_soon_Please_wait"),
        successLoading: (
          <ReactLoading type="bars" color="white" height="30px" width="30px" />
        ),
      }));
    } else {
      const detectionCanvas = document.getElementById("detection_canvas");
      if (detectionCanvas) {
        detectionCanvas.remove();
      }
      setInfo((info) => ({
        ...info,
        waitMessage: i18n.t("Please_take_a_photo_of_your_ID_Card_and_click_Next_Step_to_proceed"),
        title: i18n.t("ID_Verification"),
      }));
      clearInterval(intervalId.current);
      // createDarkenBackgroundCanvas();
      setPageState(2);
    }
  };

  const extractFaceFromBox = async (box) => {
    const regionsToExtract = [
      new faceapi.Rect(box.x, box.y, box.width, box.height),
    ];
    let faceImages = await faceapi.extractFaces(
      imageRef.current,
      regionsToExtract
    );

    const filename = `template_identifier.png`;
    // const baseHeight =
    //   document.getElementById("description_photo").offsetHeight;
    // const baseWidth = document.getElementById("description_photo").offsetWidth;
    // const canvas = document.createElement("canvas");
    // const newHeight = faceImages[0].height * (480 / baseHeight);
    // const newWidth = faceImages[0].width * (640 / baseWidth);
    // canvas.width = newWidth;
    // canvas.height = newHeight;
    // document.getElementById("detected_face_photo").src =
    //   faceImages[0].toDataURL();

    // // Scale and draw the source image to the canvas
    // canvas.getContext("2d").drawImage(faceImages[0], 0, 0, newWidth, newHeight);
    // document.getElementById("scaled_detected_face_photo").src =
    //   canvas.toDataURL();
    // const file = dataURLtoFile(canvas.toDataURL(), filename);
    const file = dataURLtoFile(faceImages[0].toDataURL(), filename);
    let uploadPath;
    if (props.content === "PREEXAM") {
      uploadPath = `${sessionId}${studentId}/images/preSystemCheck/`;
    } else if (props.content === "EXAM") {
      uploadPath = `${sessionId}${studentId}/images/systemCheck/`;
    }
    uploadObject(file, file.name, uploadPath);
  };

  const createDarkenBackgroundCanvas = () => {
    const webcamDiv = document.getElementById("webcam_div");
    // const leftPanelFullElement = document.getElementById("left_panel_full");
    const differenceHeight = 0;
    const differenceWidth = (webcamDiv.offsetWidth - videoWidth) / 2;
    // Open offset
    const canvasElement = document.createElement("canvas");
    const canvasOffsetWidth = (videoWidth * 3.3) / 4;
    const canvasOffsetHeight = (videoHeight * 3) / 4;
    canvasElement.id = "middle_canvas";
    canvasElement.style.width = `${canvasOffsetWidth}px`;
    canvasElement.style.height = `${canvasOffsetHeight}px`;
    const leftOffset =
      (parseFloat(videoWidth) - parseFloat(canvasOffsetWidth)) / 2;
    const topOffset =
      (parseFloat(videoHeight) - parseFloat(canvasOffsetHeight)) / 2;
    canvasElement.style.left = `${leftOffset}px`;
    canvasElement.style.top = `${topOffset + differenceHeight}px`;
    canvasElement.style.position = "absolute";
    canvasElement.style.zIndex = 999;
    webcamDiv.append(canvasElement);
    // Darken Left Canvas
    const leftDarkenCanvasElement = document.createElement("canvas");
    leftDarkenCanvasElement.id = "left_darken_canvas";
    leftDarkenCanvasElement.style.left = `${differenceWidth}px`;
    leftDarkenCanvasElement.style.width = `${leftOffset}px`;
    leftDarkenCanvasElement.style.height = `${videoHeight}px`;
    leftDarkenCanvasElement.style.position = "absolute";
    leftDarkenCanvasElement.style.backgroundColor = "rgba(0, 0, 0, 0.6)";
    leftDarkenCanvasElement.style.zIndex = 999;
    webcamDiv.append(leftDarkenCanvasElement);
    // Darken Top Canvas
    const topDarkenCanvasElement = document.createElement("canvas");
    const topDarkenCanvasWidth =
      parseFloat(videoWidth) - parseFloat(leftOffset);
    topDarkenCanvasElement.id = "top_darken_canvas";
    topDarkenCanvasElement.style.top = `${differenceHeight}px`;
    topDarkenCanvasElement.style.left = `${leftOffset + differenceWidth}px`;
    topDarkenCanvasElement.style.width = `${topDarkenCanvasWidth}px`;
    topDarkenCanvasElement.style.height = `${topOffset}px`;
    topDarkenCanvasElement.style.position = "absolute";
    topDarkenCanvasElement.style.backgroundColor = "rgba(0, 0, 0, 0.6)";
    topDarkenCanvasElement.style.zIndex = 999;
    webcamDiv.append(topDarkenCanvasElement);
    // Darken Bottom Canvas
    const bottomDarkenCanvasElement = document.createElement("canvas");
    const bottomDarkenCanvasHeight =
      parseFloat(canvasOffsetHeight) + parseFloat(topOffset);
    bottomDarkenCanvasElement.id = "bottom_darken_canvas";
    bottomDarkenCanvasElement.style.top = `${bottomDarkenCanvasHeight + differenceHeight
      }px`;
    bottomDarkenCanvasElement.style.left = `${leftOffset + differenceWidth}px`;
    bottomDarkenCanvasElement.style.width = `${topDarkenCanvasWidth}px`;
    bottomDarkenCanvasElement.style.height = `${topOffset}px`;
    bottomDarkenCanvasElement.style.position = "absolute";
    bottomDarkenCanvasElement.style.backgroundColor = "rgba(0, 0, 0, 0.6)";
    bottomDarkenCanvasElement.style.zIndex = 999;
    webcamDiv.append(bottomDarkenCanvasElement);
    // Darken Right Canvas
    const rightDarkenCanvasElement = document.createElement("canvas");
    const rightDarkenCanvasWidth =
      parseFloat(canvasOffsetWidth) + parseFloat(leftOffset);
    rightDarkenCanvasElement.id = "right_darken_canvas";
    rightDarkenCanvasElement.style.top = `${topOffset + differenceHeight}px`;
    rightDarkenCanvasElement.style.left = `${rightDarkenCanvasWidth + differenceWidth
      }px`;
    rightDarkenCanvasElement.style.width = `${leftOffset}px`;
    rightDarkenCanvasElement.style.height = `${canvasOffsetHeight}px`;
    rightDarkenCanvasElement.style.position = "absolute";
    rightDarkenCanvasElement.style.backgroundColor = "rgba(0, 0, 0, 0.6)";
    rightDarkenCanvasElement.style.zIndex = 999;
    webcamDiv.append(rightDarkenCanvasElement);
  };

  useEffect(() => {
    if(document.getElementById("middle_canvas") !== null)
      document.getElementById("middle_canvas").remove();
    if(document.getElementById("left_darken_canvas") !== null)
      document.getElementById("left_darken_canvas").remove();
    if(document.getElementById("top_darken_canvas") !== null)
      document.getElementById("top_darken_canvas").remove();
    if(document.getElementById("bottom_darken_canvas") !== null)
      document.getElementById("bottom_darken_canvas").remove();
    if(document.getElementById("right_darken_canvas") !== null)
      document.getElementById("right_darken_canvas").remove();
    createDarkenBackgroundCanvas();
  }, [screenWidth, screenHeight]);

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const proceedToExamHandler = () => {
    props.setPageState(4);
  };

  const handleRepeatClick = () => {
    setPageState(0);
  };

  return (
    <SystemCheckItem
      Icon={
        <React.Fragment>
          <Webcam
            className="h-100"
            ref={webcamRef}
            audio={false}
            videoConstraints={{
              width: 1280,
              height: 720,
              deviceId: props.selectedDevice.video.deviceId,
            }}
            style={{ zIndex: 1 }}
          />
          <img
            ref={imageRef}
            src={imageSrc}
            id="description_photo"
            className="h-100"
            alt=""
            style={{ display: "none" }}
          />
          {/* <img id="detected_face_photo" alt="" /> */}
          {/* <img id="scaled_detected_face_photo" alt="" /> */}
        </React.Fragment>
      }
      info={info}
      buttonHandler={buttonHandler}
      next={props.next}
      prev={props.prev}
      handleRepeatClick={handleRepeatClick}
      handleRepeatButton={handleRepeatButton}
      configuration={props.configuration}
      currentStep={props.currentStep}
    />
  );
}
export default  withNamespaces()(SecurityCheck)