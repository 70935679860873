import React, { useState, useEffect } from "react";
import SystemCheckItem from "./SystemCheckItem";
import { MdOutlineScreenShare } from "react-icons/md";
import i18n from "../../../config/i18n";
import { withNamespaces } from "react-i18next";

function CheckScreenShare(props) {
  const [info, setInfo] = useState({
    title: i18n.t("Checking_Screen_Share"),
    ready: i18n.t("WAITING"),
    waitMessage: i18n.t("Please_wait_We_are_checking_your_screen_share_capability"),
    infoCover: "col-6",
    description:
      i18n.t("This_step_will_check_whether_your_system_is_able_to_share_the_screen"),
    descriptionCover: "col-6",
    buttonText: i18n.t("Next_Step"),
  });

  useEffect(() => {
    const getScreenShareSources = async () => {
      let stream;
      try {
        stream = await navigator.mediaDevices.getDisplayMedia();
        setInfo((info) => ({
          ...info,
          ready: "OK",
          waitMessage: i18n.t("Success_Please_Click_Next_Step_to_proceed"),
        }));
      } catch (err) {
        setInfo((info) => ({
          ...info,
          waitMessage:
          i18n.t("No_screen_sharing_capability_detected"),
        }));
      }
      return stream;
    };
    let stream;
    getScreenShareSources().then((data) => {
      stream = data;
    });
    return () => {
      if (stream) {
        stream.getTracks().forEach(function (track) {
          if (track.readyState === "live") {
            track.stop();
          }
        });
      }
    };
  }, []);

  return (
    <SystemCheckItem
      Icon={
        <MdOutlineScreenShare
          size={200}
          color={info.ready === "OK" ? "#29377e" : "gray"}
        />
      }
      info={info}
      buttonHandler={props.buttonHandler}
      next={props.next}
      prev={props.prev}
      handleRepeatClick={props.handleRepeatClick}
      repeatableButton={true}
      configuration={props.configuration}
      currentStep={props.currentStep}
    />
  );
}
export default withNamespaces()(CheckScreenShare)