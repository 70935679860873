import React, { Component, createContext } from "react";

export const awscontext = createContext();
class awscontextprovider extends Component {
  state = {
    chatPanelVisible: false,
    appendMsg: null,
    setTyping: null,
    messageList: [],
    username: "",
    examFinishedByProctor: false,
    examForceFinishedByAdmin: false,
    sessionList: {},
    globalNotifyCounter: {},
    pathDownload: "",
    defaultWarnings: {
      studentAbsent: "Test Taker Absent",
      secondPerson: "Second Person Visible",
      studentReplaced: "Test Taker Substituted",
      gazeDirection: "Looking Away from The Screen",
      voiceDetected: "Microphone Voice",
      windowChange: "Exam Page Lost",
      phoneIsUse: "Use of Smartphone",
      forribenHardware: "Unauthorized Devices Present",
    },
    // isAIactive: false,
    isAIactive: undefined,
    showToStudent: undefined

  };
  // setIsAIactive = (val=null) => {
  //   this.setState({ isAIactive: val })
  // }

  setIsAIactive = (val) => {
      this.setState({ isAIactive: val })
    }

  setShowToStudent = (val) => {
    this.setState({showToStudent: val})
  };

  setDefaultWarnings = (val = null) => {
    this.setState({ defaultWarnings: val })
  }

  setPathDownload = (val) => {
    this.setState({ pathDownload: val });
  };

  setGlobalNotifyCounter = (val) => {
    this.setState({ globalNotifyCounter: val })
  }
  setCurrentState = (currentState) => {
    this.setState({ currentStateContext: currentState });
  };

  setExamFinishedByProctor = (val) => {
    this.setState({ examFinishedByProctor: val });
  };

  setExamForceFinishedByAdmin = (val) => {
    this.setState({ examForceFinishedByAdmin: val });
  };

  setMessageList = (messageList) => {
    this.setState(messageList);
  };
  setUsername = (username) => {
    this.setState({ username });
  };

  setChatPanelVisible = (value) => {
    this.setState({ chatPanelVisible: value });
  };

  setAppendMsg = (appendMsg, setTyping, resetList) => {
    resetList();
    this.setState({ appendMsg: appendMsg, setTyping: setTyping });
    this.state.messageList.forEach((element) => {
      const user =
        element.frommesaj === this.state.username
          ? ""
          : element.frommesaj + ": ";
      const position =
        element.frommesaj === this.state.username ? "right" : "left";

      appendMsg({
        type: "text",
        content: { text: user + element.messageText },
        position: position,
      });
      //appendMsg(element.frommesaj, element.messageText);
    });
  };

  getAppendMsg = (frommesaj, messageText) => {
    const setTyping = this.state.setTyping;
    setTyping(true);
    const addMessage = this.state.appendMsg;
    addMessage({
      type: "text",
      content: { text: frommesaj + ": " + messageText },
      //position: "right",
    });
  };

  setSessionList = (val) => {
    this.setState({val});
  };

  render() {
    return (
      <awscontext.Provider
        value={{
          ...this.state,
          setAppendMsg: this.setAppendMsg,
          getAppendMsg: this.getAppendMsg,
          setChatPanelVisible: this.setChatPanelVisible,
          setMessageList: this.setMessageList,
          setCurrentState: this.setCurrentState,
          setUsername: this.setUsername,
          setExamFinishedByProctor: this.setExamFinishedByProctor,
          setExamForceFinishedByAdmin: this.setExamForceFinishedByAdmin,
          setSessionList: this.setSessionList,
          setPathDownload: this.setPathDownload,
          setGlobalNotifyCounter: this.setGlobalNotifyCounter,
          setDefaultWarnings: this.setDefaultWarnings,
          setIsAIactive: this.setIsAIactive,
          setShowToStudent: this.setShowToStudent

        }}
      >
        {this.props.children}
      </awscontext.Provider>
    );
  }
}
export default awscontextprovider;
