import { OpenVidu } from "openvidu-browser";
import axios from "axios";

const config = require("../config/awsconfig.json");
const check_api = config.openvidu.kms.endpoints.check;
const session_api = config.openvidu.kms.endpoints.session;
const connect_api = config.openvidu.kms.endpoints.session.connection;
const token_api = config.openvidu.kms.endpoints.token;
const recording_api = config.openvidu.kms.endpoints.recording;

class OpenViduConnector {
  constructor() {
    this.openViduClient = new OpenVidu();
    this.openViduClient.setAdvancedConfiguration({
      publisherSpeakingEventsOptions: {
        interval: 500,   // kaç milisaniyede bir baksın
        threshold: -60  // Threshold volume in dB (-60 normal konuşma)
      }
    })
  }

  initializeSession = () => {
    return this.openViduClient.initSession();
  };

  // OpenVidu Lambda API
  createSession = async (session) => {
    try {
      const createResult = await axios.post(
        `${config.api.invokeUrl}${session_api.context}`,
        session
      );
      return createResult.data;
    } catch (err) {
      if (err.response.status === 409) {
        return this.getSession(session.customSessionId)
          .then((getResult) => {
            return getResult;
          })
          .catch((err) => {
            console.log("The session is not created nor retrieved");
            throw err;
          });
      }
    }
  };

  checkSession = async (sessionId) => {
    const url =
      `${config.api.invokeUrl}${check_api.context}${check_api.id.context}`.replace(
        "{id}",
        sessionId
      );
    try {
      return await axios.get(url);
    } catch (err) {
      throw err;
    }
  };

  getSession = async (sessionId) => {
    const url =
      `${config.api.invokeUrl}${session_api.context}${session_api.id.context}`.replace(
        "{id}",
        sessionId
      );
    try {
      const result = await axios.get(url);
      return result.data;
    } catch (err) {
      throw err;
    }
  };

  connectToSession = async (sessionId, connectionProperties) => {
    const url =
      `${config.api.invokeUrl}${session_api.context}${session_api.id.context}${connect_api.context}`.replace(
        "{id}",
        sessionId
      );
    try {
      const result = await axios.post(url, connectionProperties);
      console.log(
        `Connected to session: ${sessionId} as ${connectionProperties.role}`
      );
      return result.data;
    } catch (err) {
      console.log("Openvidu Connet Session Error", err);
      throw err;
    }
  };

  getSessionToken = async (tokenProperties) => {
    try {
      const result = await axios.post(
        `${config.api.invokeUrl}${token_api.context}`,
        tokenProperties
      );
      return result.data;
    } catch (err) {
      throw err;
    }
  };

  startRecordingOnSession = async (recordingProperties) => {
    try {
      const result = await axios.post(
        `${config.api.invokeUrl}${recording_api.context}${recording_api.start.context}`,
        recordingProperties
      );
      console.log(
        `Recorder has successfully started on session: ${recordingProperties.session}`
      );
      return result.data;
    } catch (err) {
      throw err;
    }
  };

  stopRecordingOnSession = async (recordingId) => {
    const url =
      `${config.api.invokeUrl}${recording_api.context}${recording_api.stop.context}${recording_api.stop.id.context}`.replace(
        "{id}",
        recordingId
      );
    try {
      await axios.post(url);
    } catch (err) {
      throw err;
    }
  };

  // Node Server API
  getToken = async (sessionName, instituteId, role) => {
    const data = {
      meetingId: sessionName,
      instituteId,
      role,
    };
    try {
      const result = await axios.post(
        `${config.openvidu.node.server}${config.openvidu.node.endpoints.getToken}`,
        data
      );
      console.log(`Token successfully received from server: ${result.data[0]}`);
      return result.data[0];
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  startRecording = async (session, name, source) => {
    let resolution;
    let hasAudio;
    if (source === "webcam") {
      resolution = "640x480";
      hasAudio = true;
    } else if (source === "screenShare") {
      resolution = "1920x1080";
      hasAudio = false;
    }
    const data = {
      name,
      session: session.sessionId,
      outputMode: "INDIVIDUAL",
      hasAudio,
      hasVideo: true,
      resolution,
    };
    try {
      const result = await axios.post(
        `${config.openvidu.node.server}${config.openvidu.node.endpoints.startRecording}`,
        data
      );
      console.log("Recording has successfully started: ", result);
      return result.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  stopRecording = async (recording) => {
    const data = {
      recording,
    };
    try {
      const result = await axios.post(
        `${config.openvidu.node.server}${config.openvidu.node.endpoints.stopRecording}`,
        data
      );
      console.log("Recording has successfully stopped: ", result);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  fetchAll = async () => {
    try {
      const result = await axios.get(
        `${config.openvidu.node.server}${config.openvidu.node.endpoints.fetchAll}`
      );
      console.log("Fetch All Results: ", result);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  removeUser = async (sessionName) => {
    const data = {
      sessionName,
    };
    try {
      const result = await axios.post(
        `${config.openvidu.node.server}${config.openvidu.node.endpoints.removeUser}`,
        data
      );
      console.log(`You have been removed from the session: ${sessionName}`);
      console.log(result);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  fetchInfo = async (sessionName) => {
    const data = {
      sessionName,
    };
    try {
      const result = await axios.post(
        `${config.openvidu.node.server}${config.openvidu.node.endpoints.fetchInfo}`,
        data
      );
      console.log("Session fetch is successful");
      console.log(result);
      return result;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  closeSession = async (sessionName) => {
    const data = {
      sessionName,
    };
    try {
      const result = await axios.post(
        `${config.openvidu.node.server}${config.openvidu.node.endpoints.closeSession}`,
        data
      );
      console.log("Session successfully closed");
      console.log(result);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  // Common Functions
  joinSession = async (
    session,
    connection,
    username,
    source,
    selectedDevice,
    htmlElementId
  ) => {
    let stream;
    let publisher;
    if (connection.role === "PUBLISHER") {
      // this.setPublisherSessionEvents(session);
    } else if (connection.role === "SUBSCRIBER") {
      this.setSubscriberSessionEvents(session, htmlElementId);
    }
    await session.connect(connection.token, { clientData: username });
    if (connection.role === "PUBLISHER") {
      if (source === "webcam") {
        stream = await this.openViduClient.getUserMedia({
          audioSource: selectedDevice.audio.deviceId,
          videoSource: selectedDevice.video.deviceId,
          resolution: "1280x720",
          frameRate: 30,
        });
        publisher = this.openViduClient.initPublisher(htmlElementId, {
          audioSource: stream.getAudioTracks()[0], // The source of audio. If undefined default microphone
          videoSource: stream.getVideoTracks()[0], // The source of video. If undefined default webcam
          hasAudio: true,
          hasVideo: true,
          publishAudio: true, // Whether you want to start publishing with your audio unmuted or not
          publishVideo: true, // Whether you want to start publishing with your video enabled or not
          resolution: "1280×720", // The resolution of your video
          frameRate: 30, // The frame rate of your video
          insertMode: "APPEND", // How the video is inserted in the target element 'video-container'
          mirror: false, // Whether to mirror your local video or not
        });
      } else if (source === "screenShare") {
        stream = await this.openViduClient.getUserMedia({
          audioSource: selectedDevice.audio.deviceId,
          videoSource: "screen",
          resolution: "1920x1080",
          frameRate: 30,
        });
        publisher = this.openViduClient.initPublisher(htmlElementId, {
          videoSource: stream.getVideoTracks()[0],
          audioSource: stream.getAudioTracks()[0],
          hasVideo: true,
          hasAudio: false,
          publishVideo: true,
          publishAudio: false,
          frameRate: 30,
        });
      } else if (source.includes("mobile")) {
        stream = await this.openViduClient.getUserMedia({
          audioSource: undefined,
          videoSource: undefined,
          resolution: "1280x720",
          frameRate: 30,
        });
        publisher = this.openViduClient.initPublisher(htmlElementId, {
          audioSource: stream.getAudioTracks()[0], // The source of audio. If undefined default microphone
          videoSource: stream.getVideoTracks()[0], // The source of video. If undefined default webcam
          hasVideo: true,
          hasAudio: false,
          publishAudio: false, // Whether you want to start publishing with your audio unmuted or not
          publishVideo: true, // Whether you want to start publishing with your video enabled or not
          resolution: "1280×720", // The resolution of your video
          frameRate: 30, // The frame rate of your video
          insertMode: "APPEND", // How the video is inserted in the target element 'video-container'
          mirror: false, // Whether to mirror your local video or not
        });
      }
      publisher.updatePublisherSpeakingEventsOptions({
        interval: 500,   // Frequency of the polling of audio streams in ms
        threshold: -50  // Threshold volume in dB
      });
      this.setPublisherEvents(publisher);
      await session.publish(publisher);
    }
    // publisher.updatePublisherSpeakingEventsOptions({
    //   publisherSpeakingEventsOptions: {
    //     interval: 100,   // Frequency of the polling of audio streams in ms
    //     threshold: -50  // Threshold volume in dB
    //   }

    // });
    return publisher;
  };

  createPublisher = async (source, stream, htmlElementId) => {
    return null;
  };

  setPublisherSessionEvents = (session) => {
    session.on("connectionCreated", (event) => { });
    session.on("connectionDestroyed", (event) => { });
    session.on("streamCreated", (event) => {
      console.log("SERKAN buraya girmemen lazim");
    });
    session.on("streamDestroyed", (event) => { });
    session.on("sessionDisconnected", (event) => { });
    session.on("recordingStarted", (event) => { });
    session.on("recordingStopped", (event) => { });

    // On every asynchronous exception...
    session.on("exception", (exception) => {
      console.warn(exception);
    });
  };

  setSubscriberSessionEvents = (session, htmlElementId) => {
    session.on("connectionCreated", (event) => { });
    session.on("connectionDestroyed", (event) => {
      console.log("connection descroyed", event);
    });
    session.on("streamCreated", (event) => {
      const subscriber = session.subscribe(event.stream, htmlElementId);
    });
    session.on("streamDestroyed", (event) => { });
    session.on("sessionDisconnected", (event) => {
      console.log("session disconnected", event);
    });
    session.on("recordingStarted", (event) => { });

    session.on("recordingStopped", (event) => { });
    // On every asynchronous exception...
    session.on("exception", (exception) => {
      console.warn("exception => ", exception);
    });
  };

  setPublisherEvents = (publisher, session, type) => {
    // When the publisher stream has started playing media...
    publisher.on("accessAllowed", () => { });

    publisher.on("accessDenied", (event) => { });

    publisher.on("accessDialogOpened", (event) => { });

    publisher.on("accessDialogClosed", (event) => { });

    // When the publisher stream has started playing media...
    publisher.on("streamCreated", (event) => { });

    // When our HTML video has been added to DOM...
    publisher.on("videoElementCreated", (event) => { });

    // When the HTML video has been appended to DOM...
    publisher.on("videoElementDestroyed", (event) => { });

    // When the publisher stream has started playing media...
    publisher.on("streamPlaying", (event) => { });

    publisher.on("inactive", (event) => { });
  };
}

export default OpenViduConnector;
