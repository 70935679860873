/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import { awscontext } from "../../awscontext";
import ChatPanel from "./ChatPanel";
import i18n from "../../../config/i18n";
import { withNamespaces } from "react-i18next";

 function ChatIcon(props) {
  const [chatPanelVisibleLocal, setChatPanelVisibleLocal] = useState(false);
  const { chatPanelVisible, setChatPanelVisible } = useContext(awscontext);
  useEffect(() => {
    if (chatPanelVisible) {
      setChatPanelVisibleLocal(true);
      setChatPanelVisible(false);
    }
  }, [chatPanelVisible]);
  return (
    <React.Fragment>
      <div
        className="d-flex align-items-center justify-content-start"
        onClick={() => {
          setChatPanelVisibleLocal(!chatPanelVisibleLocal);
        }}
        style={{
          position: "fixed",
          width: 188,
          height: 44,
          bottom: 0,
          right: 10,
          backgroundColor: "#29377e",
          border: "1px solid #29377e",
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          color: "white",
          fontSize: 16,
          cursor: "pointer",
          paddingLeft: 15,
        }}
      >
        <span>{chatPanelVisibleLocal ? i18n.t("Click_to_close") : i18n.t("Proctor_chat")}</span>
      </div>
      <ChatPanel
        sendTextMessage={props.sendTextMessage}
        panelVisibility={chatPanelVisibleLocal}
      />
    </React.Fragment>
  );
}
export default withNamespaces()(ChatIcon);