/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect, useContext } from "react";
import Webcam from "react-webcam";
import SystemCheckItem from "./SystemCheckItem";
import Switch from "react-switch";
import { MdRadioButtonUnchecked } from "react-icons/md";
import { BsCheckCircle } from "react-icons/bs";
import ReactLoading from "react-loading";
import { awscontext } from "../../awscontext";
import i18n from "../../../config/i18n";
import { withNamespaces } from "react-i18next";

function CheckRoomScan(props) {
  const information = props.information;
  const onboardingProcess = information.session.onboardingProcess;
  const webcamRef = useRef(null);
  const remoteMobileVideo = useRef(null);
  const [useMobile, setUseMobile] = useState(false);
  const context = useContext(awscontext);
  //test
  const videoConstraints = {
    width: 1280,
    height: 720,
    deviceId: props.selectedDevice.video.deviceId,
  };
  const [info, setInfo] = useState({
    title: i18n.t("Checking_Exam_Environment"),
    ready: i18n.t("WAITING"),
    waitMessage: i18n.t("Please_wait_until_the_webcam_is_getting_ready"),
    description: (
      <div className="d-flex flex-column" id="descriptionText">
        <div>
          {i18n.t("This_step_will_check_your_exam_environment_Please_follow_the_instructions")}

        </div>
      </div>
    ),
    descriptionCover: "col-6",
    buttonText: i18n.t("Next_Step"),
  });
  const currentState = props.currentInPageState;
  useEffect(() => {
    if (info) {
      setUseMobile(information.student.examRules.mobileCameraUsage)
      setInfo((info) => ({
        ...info, ready: "OK"
      }))
    }

  }, [])
  useEffect(() => {
    const requirementPanel = document.getElementById("requirement_panel");
    requirementPanel.style.display = "none";
    const webcamPanel = document.getElementById("webcam_div");
    webcamPanel.classList.toggle("fixedHeight");
    webcamPanel.classList.toggle("singleFixedHeight");
  }, []);

  useEffect(() => {
    checkCurrentCameraState();
    document.getElementById("requirement_panel").style.display = "none";
  }, []);

  useEffect(() => {
    if (webcamRef.current) {
      if (
        onboardingProcess === "PROCTOR_ASSISTED" &&
        props.content !== "PREEXAM"
      ) {
      } else {
        webcamRef.current.video.oncanplay = () => {
          setInfo((info) => ({
            ...info,
            ready: "OK",
          }));
        };
      }
      webcamRef.current.video.onsuspend = async () => {
        checkCurrentCameraState();
      };
    }
  }, [webcamRef]);

  useEffect(() => {
    const session = props.settings.mobile_0.session;
    let subscriber = null;
    if (session) {
      const remoteVideoElement = document.getElementById("remote_mobile_video");
      if (useMobile) {
        if (subscriber) {
          subscriber.addVideoElement(remoteMobileVideo.current);
        } else {
          for (const rmtCon of session.remoteConnections) {
            if (rmtCon[1].stream) {
              subscriber = session.subscribe(rmtCon[1].stream, null);
              subscriber.addVideoElement(remoteMobileVideo.current);
            }
          }
        }
      } else {
        if (remoteVideoElement) {
          remoteVideoElement.style.display = "none";
        }
      }
    }

    return () => {
      if (subscriber) {
        session.unsubscribe(subscriber);
      }
    };
  }, [useMobile]);

  useEffect(() => {
    if (currentState === 0) {
      setInfo((info) => ({
        ...info,
        waitMessage: i18n.t("Please_show_the_top_and_under_your_desk"),
      }));
      if (
        onboardingProcess === "PROCTOR_ASSISTED" &&
        props.content !== "PREEXAM"
      ) {
        props.sendProctorSignalMessage(
          "ENVIRONMENT_SCAN",
          "Desk Scan",
          currentState
        );
      }
    } else if (currentState === 1) {
      const prevListItemElement = document.getElementById("workspace_span");
      setInfo((info) => ({
        ...info,
        waitMessage: i18n.t("Please_show_the_area_behind_your_display"),
      }));
      if (
        onboardingProcess === "PROCTOR_ASSISTED" &&
        props.content !== "PREEXAM"
      ) {
        props.sendProctorSignalMessage(
          "ENVIRONMENT_SCAN",
          "Blindspot Scan",
          currentState
        );
      }

      styleCurrentElement(prevListItemElement);
    } else if (currentState === 2) {
      const prevListItemElement = document.getElementById(
        "over_and_under_check"
      );
      setInfo((info) => ({
        ...info,
        waitMessage:
          i18n.t("Please_move_your_camera_and_show_the_four_corners_of_your_room"),
      }));
      if (
        onboardingProcess === "PROCTOR_ASSISTED" &&
        props.content !== "PREEXAM"
      ) {
        props.sendProctorSignalMessage(
          "ENVIRONMENT_SCAN",
          "Room Scan",
          currentState
        );
      }
      styleCurrentElement(prevListItemElement);
    } else if (currentState === 3) {
      const prevListItemElement = document.getElementById("full_room_scan");
      setInfo((info) => ({
        ...info,
        waitMessage:
          i18n.t("Please_show_your_ears_to_the_camera_to_make_sure_there_are_no_digital_devices"),
      }));
      if (
        onboardingProcess === "PROCTOR_ASSISTED" &&
        props.content !== "PREEXAM"
      ) {
        props.sendProctorSignalMessage(
          "ENVIRONMENT_SCAN",
          "Headphone Scan",
          currentState
        );
      }
      styleCurrentElement(prevListItemElement);
    }
    context.setCurrentState(currentState);
  }, [currentState]);

  const handleRepeatClick = () => {
    if (onboardingProcess === "PROCTOR_ASSISTED") {
      if (currentState === 0) {
        props.sendProctorSignalMessage(
          "ENVIRONMENT_SCAN",
          "Desk Scan",
          currentState
        );
      } else if (currentState === 1) {
        props.sendProctorSignalMessage(
          "ENVIRONMENT_SCAN",
          "Blindspot Scan",
          currentState
        );
      } else if (currentState === 2) {
        props.sendProctorSignalMessage(
          "ENVIRONMENT_SCAN",
          "Room Scan",
          currentState
        );
      } else if (currentState === 3) {
        props.sendProctorSignalMessage(
          "ENVIRONMENT_SCAN",
          "Headphone Scan",
          currentState
        );
      }
    } else {
      props.handleRepeatClick();
    }
  };

  const checkCurrentCameraState = async () => {
    let videoState = "HARDWARE_NOT_EXIST";
    try {
      await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: props.selectedDevice.video.deviceId,
        },
      });
      videoState = "OK";
    } catch (err) {
      videoState = "PERMISSION_DENIED";
    }
    if (videoState === "OK") {
    } else if (videoState === "HARDWARE_NOT_EXIST") {
      setInfo((info) => ({
        ...info,
        ready: "FAILED",
        waitMessage:
          i18n.t("No_webcam_detected_Please_plug_connect_a_webcam_and_repeat_the_system_check"),
      }));
    } else if (videoState === "PERMISSION_DENIED") {
      setInfo((info) => ({
        ...info,
        ready: "FAILED",
        waitMessage:
          i18n.t("Webcam_detected_but_the_permissions_are_denied_Please_check_your_Camera_Privacy_Settings"),
      }));
    }
  };

  const changeUseMobile = () => {
    setUseMobile(!useMobile);
  };

  const styleCurrentElement = (element) => {
    element.style.color = "rgba(90, 188, 42, 1)";
  };

  const buttonHandler = () => {
    props.handleInPageState();
  };

  const RenderRoomScanSubSteps = (condition) => {
    return (
      <React.Fragment>
        <div
          className="d-flex flex-row justify-content-around align-items-center"
          style={{ paddingTop: 10, paddingBottom: 10 }}
        >
          {currentState === 0 ? (
            <div
              id="workspace_span"
              className="d-flex flex-row"
              style={{
                borderColor: "#FFBF00",
                borderRadius: 10,
                marginVertical: 20,
                backgroundColor: "#FFBF00",
                color: "#29377e",
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              <span>&nbsp;</span>
              <MdRadioButtonUnchecked size={24} className="mr-2" />
              {i18n.t("Desk_Scan")} <span>&nbsp;</span>
              <ReactLoading
                type="bars"
                color="#29377e"
                height="30px"
                width="30px"
              />
              <span>&nbsp;</span>
            </div>
          ) : (
            <div
              id="workspace_span"
              className="d-flex flex-row"
              style={{
                color: "rgba(141, 141, 142, 0.7)",
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              {currentState >= 1 ? (
                <BsCheckCircle size={24} className="mr-2" />

              ) : (
                <MdRadioButtonUnchecked size={24} className="mr-2" />
              )}
              {i18n.t("Desk_Scan")}
            </div>
          )}
          {currentState === 1 ? (
            <div
              id="over_and_under_check"
              className="d-flex flex-row"
              style={{
                borderColor: "#FFBF00",
                borderRadius: 10,
                marginVertical: 20,
                backgroundColor: "#FFBF00",
                color: "#29377e",
                fontSize: 18,
                fontWeight: "bold",
              }}

            >
              <span>&nbsp;</span>
              <MdRadioButtonUnchecked size={24} className="mr-2" />
              {i18n.t("Blindspot_Scan")}
              <span>&nbsp;</span>
              <ReactLoading
                type="bars"
                color="#29377e"
                height="30px"
                width="30px"
              />
              <span>&nbsp;</span>
            </div>
          ) : (
            <div
              id="over_and_under_check"
              className="d-flex flex-row"
              style={{
                color: "rgba(141, 141, 142, 0.7)",
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              {currentState >= 2 ? (
                <BsCheckCircle size={24} className="mr-2" />
              ) : (
                <MdRadioButtonUnchecked size={24} className="mr-2" />
              )}

              {i18n.t("Blindspot_Scan")}
            </div>
          )}
          {currentState === 2 ? (
            <div
              id="full_room_scan"
              className="d-flex flex-row"
              style={{
                borderColor: "#FFBF00",
                borderRadius: 10,
                marginVertical: 20,
                backgroundColor: "#FFBF00",
                color: "#29377e",
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              <span>&nbsp;</span>
              <MdRadioButtonUnchecked size={24} className="mr-2" />
              {i18n.t("Room_Scan")}
              <span>&nbsp;</span>
              <ReactLoading
                type="bars"
                color="#29377e"
                height="30px"
                width="30px"
              />
              <span>&nbsp;</span>
            </div>
          ) : (
            <div
              id="full_room_scan"
              className="d-flex flex-row"
              style={{
                color: "rgba(141, 141, 142, 0.7)",
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              {currentState >= 3 ? (
                <BsCheckCircle size={24} className="mr-2" />
              ) : (
                <MdRadioButtonUnchecked size={24} className="mr-2" />
              )}

              {i18n.t("Room_Scan")}
            </div>
          )}
          {currentState === 3 ? (
            <div
              id="ear_scan"
              className="d-flex flex-row"
              style={{
                borderColor: "#FFBF00",
                borderRadius: 10,
                marginVertical: 20,
                backgroundColor: "#FFBF00",
                color: "#29377e",
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              <span>&nbsp;</span>
              <MdRadioButtonUnchecked size={24} className="mr-2" />
              {i18n.t("Headphone_Scan")}
              <span>&nbsp;</span>
              <ReactLoading
                type="bars"
                color="#29377e"
                height="30px"
                width="30px"
              />
              <span>&nbsp;</span>
            </div>
          ) : (
            <div
              id="ear_scan"
              className="d-flex flex-row"
              style={{
                color: "rgba(141, 141, 142, 0.7)",
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              {currentState >= 4 ? (
                <BsCheckCircle size={24} className="mr-2" />
              ) : (
                <MdRadioButtonUnchecked size={24} className="mr-2" />
              )}

              {i18n.t("Headphone_Scan")}
            </div>
          )}

        </div>
      </React.Fragment>
    );
  };

  return (
    <SystemCheckItem
      Icon={
        information.student.examRules.mobileCameraUsage && useMobile ? (
          <React.Fragment>
            <div
              className="d-flex flex-column h-100 w-100"
              style={{ backgroundColor: "rgba(242,242,242,1)" }}
            >
              {RenderRoomScanSubSteps()}
              <div className="w-100 h-100">
                <div
                  className="d-flex flex-column align-items-center mb-2"
                  style={{
                    position: "absolute",
                    zIndex: 999,
                    top: 47,
                    left: 0,
                    right: 0,
                  }}
                >
                  {i18n.t("Use_Mobile")}
                  <Switch checked={useMobile} onChange={changeUseMobile} />
                </div>
                <video
                  ref={remoteMobileVideo}
                  id="remote_mobile_video"
                  muted
                  style={{ maxHeight: "80%", maxWidth: "100%" }}
                />
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div
              className="d-flex flex-column h-100 w-100 fixedWidth"
              style={{ backgroundColor: "rgba(242,242,242,1)", height: "calc(100% - 40px)" }}
            >
              {RenderRoomScanSubSteps()}
              <div className="w-100" style={{ height: "calc(99% - 90px)" }}>
                {information.student.examRules.mobileCameraUsage &&
                  props.content !== "PREEXAM" ? (
                  <div
                    className="d-flex flex-column align-items-center mb-2"
                    style={{
                      position: "absolute",
                      zIndex: 999,
                      top: 47,
                      left: 0,
                      right: 0,
                    }}
                  >
                    {i18n.t("Use_Mobile")}
                    <Switch checked={useMobile} onChange={changeUseMobile} />
                  </div>
                ) : null}
                <Webcam
                  className="h-100"
                  audio={false}
                  ref={webcamRef}
                  videoConstraints={videoConstraints}
                />
              </div>
            </div>
          </React.Fragment>
        )
      }
      info={info}
      buttonHandler={currentState === 3 ? props.buttonHandler : buttonHandler}
      changeUseMobile={changeUseMobile}
      next={props.next}
      prev={props.prev}
      handleRepeatClick={handleRepeatClick}
      repeatableButton={true}
      configuration={props.configuration}
      currentStep={props.currentStep}
    />
  );
}
export default withNamespaces()(CheckRoomScan)