import React, { useState, useEffect } from "react";
import SystemCheckItem from "./SystemCheckItem";
import { FaRestroom } from "react-icons/fa";
import i18n from "../../../config/i18n";
import { withNamespaces } from "react-i18next";

function CheckToiletBreak(props) {
  //•	You can take toilet breaks during the exam.
  const information = props.information;
  const [info, setInfo] = useState({
    title: i18n.t("Toilet_Break"),
    ready: i18n.t("WAITING"),
    waitMessage: information.session.systemCheck.toiletBreakPermission
      ? i18n.t("You_can_take_toilet_breaks_during_the_exam")
      : i18n.t("If_you_must_please_go_to_the_toilet_before_your_exam_starts"),
    infoCover: "col-6",
    description:
      i18n.t("This_step_will_indicate_whether_you_are_allowed_to_take_a_toilet_break"),
    descriptionCover: "col-6",
    buttonText: i18n.t("Next_Step"),
  });

  useEffect(() => {
    const repeatButtonDiv = document.getElementById("repeatable_button_div");
    repeatButtonDiv.style.display = "none";
    const repeatButtonTopDiv = document.getElementById(
      "repeatable_button_top_div"
    );
    repeatButtonTopDiv.style.display = "none";
  }, []);

  useEffect(() => {
    setInfo((info) => ({
      ...info,
      ready: "OK",
    }));
  }, []);

  return (
    <SystemCheckItem
      Icon={
        <FaRestroom
          size={150}
          color={info.ready === "OK" ? "#29377e" : "gray"}
        />
      }
      info={info}
      buttonHandler={props.buttonHandler}
      next={props.next}
      prev={props.prev}
      handleRepeatClick={props.handleRepeatClick}
      repeatableButton={true}
      configuration={props.configuration}
      currentStep={props.currentStep}
    />
  );
}
export default withNamespaces() (CheckToiletBreak);
