import React, { useState, useEffect } from "react";
import ExamStudent from "../connection/ExamStudent";
import ExamReadyScreen from "../ExamReadyScreen";
import ExamUrlScreen from "../ExamUrlScreen";
import Header from "../general/Header";
import SecurityCheck from "../SecurityCheck";
import CheckAllowedMaterial from "../system-check-steps/CheckAllowedMaterial";
import CheckAudio from "../system-check-steps/CheckAudio";
import CheckInternetConnection from "../system-check-steps/CheckInternetConnection";
import CheckMaterialDestroyed from "../system-check-steps/CheckMaterialDestroyed";
import CheckNumberOfDisplays from "../system-check-steps/CheckNumberOfDisplays";
import CheckRoomScan from "../system-check-steps/CheckRoomScan";
import CheckScreenShare from "../system-check-steps/CheckScreenShare";
import CheckToiletBreak from "../system-check-steps/CheckToiletBreak";
import CheckWebcam from "../system-check-steps/CheckWebcam";
import SystemCheck from "../SystemCheck";
import TermsOfAgreementScreen from "../TermsOfAgreementScreen";
import axios from "axios";
import querystring from "querystring";
import LoadingIcon from "../../LoadingIcon";
import * as faceapi from "face-api.js";
import ReactNotification from "react-notifications-component";
import uniqid from "uniqid";
import DownloadScreen from "../DownloadScreen";
import i18n from "../../../config/i18n";
import { withNamespaces } from "react-i18next";
const config = require("../../../config/awsconfig.json");

function PreSystemCheck(props) {
  const [pageState, setPageState] = useState(0);
  const [information, setInformation] = useState(false);
  const settings = {
    mobile_0: {
      session: null,
    },
    pc: {
      webcam: {
        session: null,
      }
    },
  };
  const content = "PREEXAM";
  const [incomingCallInformation] = useState({
    proctorId: null,
    meetingId: null,
    status: "FINISHED",
  });
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [dependencyReady, setDependencyReady] = useState(false);
  const [interceptorReady, setInterceptorReady] = useState(false);
  const [secondEnterance, setSecondEnterance] = useState(false);

  let loginTimestamp = 0;
  let technicalMessageCount = 0;

  useEffect(() => {
    if (information) {
      if (information.session.useLdb === true && !props.history.location.pathname.includes("lockdownBrowser")) {
        setPageState(10);
      }
    }
  }, [information]);

  useEffect(() => {
    if (information.student) {
      if (information.student.preSystemCheck) {
        let presystem = information.student.preSystemCheck
        if (presystem.audioShareable === true || presystem.internetConnectionStatus === true || presystem.preExamMaterialCheck || presystem.preExamRoomScan || presystem.screenShareable || presystem.webcamShareable) {
          setSecondEnterance(true)
        }
      }
    }
  }, [information]);

  useEffect(() => {
    const retrieveToken = async () => {
      const res = await axios.post(
        config.api.configuration.token_base_path,
        querystring.stringify({
          grant_type: "client_credentials",
          scope: config.api.configuration.scope,
        }),
        {
          auth: {
            username: config.api.configuration.auth.username,
            password: config.api.configuration.auth.password,
          },
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      return res.data.access_token;
    };

    const createAxiosInterceptor = async () => {
      await retrieveToken().then(async (token) => {
        await axios.interceptors.request.use(
          (config) => {
            config.headers = {
              Authorization: `${token}`,
            };
            return config;
          },
          (error) => {
            Promise.reject(error);
          }
        );
        setInterceptorReady(true);
      });
    };

    createAxiosInterceptor();
  }, []);

  useEffect(() => {
    const loadDetectionDependencies = async () => {
      await faceapi.nets.tinyFaceDetector.loadFromUri(
        `${config.api.s3}${config.detection_model.path}`
      );
      await faceapi.nets.faceLandmark68Net.loadFromUri(
        `${config.api.s3}${config.detection_model.path}`
      );
      await faceapi.nets.faceRecognitionNet.loadFromUri(
        `${config.api.s3}${config.detection_model.path}`
      );
      await faceapi.nets.faceExpressionNet.loadFromUri(
        `${config.api.s3}${config.detection_model.path}`
      );
    };
    loadDetectionDependencies().then(() => {
      setDependencyReady(true);
    });
  }, []);

  const loadTawkTo = (propertyId, key, configuration) => {
    if (!window) {
      throw new Error("DOM is unavailable");
    }

    window.Tawk_API = window.Tawk_API || {};
    window.Tawk_LoadStart = new Date();
    window.Tawk_API.visitor = configuration;
    // window.Tawk_API.onLoad = function () {
    //   window.Tawk_API.setAttributes(configuration, function (err) {
    //     if (err) {
    //       console.log("Tawk to set attribute error: ", err);
    //     }
    //   });
    // };

    const tawk = document.getElementById("tawkId");
    if (tawk) {
      // Prevent TawkTo to create root script if it already exists
      return window.Tawk_API;
    }

    if (!key) {
      throw new Error(
        "Key not provided. Get key from tawk dashboard - Direct Chat Link"
      );
    }

    const script = document.createElement("script");
    script.id = "tawkId";
    script.async = true;
    script.src = "https://embed.tawk.to/" + propertyId + "/" + key;
    script.charset = "UTF-8";
    script.setAttribute("crossorigin", "*");

    const first_script_tag = document.getElementsByTagName("script")[0];
    if (!first_script_tag || !first_script_tag.parentNode) {
      throw new Error("DOM is unavailable");
    }

    first_script_tag.parentNode.insertBefore(script, first_script_tag);
  };

  const getLatestTechnicalChatMessage = (meetingId, studentName) => {
    const iFrameList = document.getElementsByTagName("iframe");
    const tawkToIFrame = [].slice
      .call(iFrameList)
      .find(
        (iframe) =>
          iframe.offsetHeight / iframe.offsetWidth > 1 && iframe.id !== "exam"
      );
    if (tawkToIFrame) {
      const currentDocument = tawkToIFrame.contentWindow.document;
      const messageBubbleCollection = currentDocument.getElementsByClassName(
        "tawk-message-bubble"
      );
      // console.log(messageBubbleCollection.length);
      const chatInputElement = currentDocument.getElementById(
        "tawk-chatinput-container"
      );
      if (chatInputElement && chatInputElement.style.display !== "none") {
        let agentName;
        try {
          agentName = currentDocument
            .getElementsByClassName("tawk-agent-name")[0]
            .innerHTML.trim();
        } catch (err) {
          agentName = "Technical Agent";
        }

        const messageBubbleList = [].slice.call(messageBubbleCollection);
        let currentTechnicalMessageCount = 0;
        messageBubbleList.forEach((messageBubble) => {
          try {
            const messageTime = calculateUnixTimeStampFromTime(
              messageBubble.getElementsByTagName("time")[0].innerHTML
            );
            if (messageTime >= loginTimestamp) {
              if (
                messageBubble.getElementsByClassName("tawk-visitor-chat")
                  .length > 0
              ) {
                const messageText =
                  messageBubble.getElementsByTagName("span")[0].innerHTML;
                if (messageText !== "New messages") {
                  currentTechnicalMessageCount++;
                  if (currentTechnicalMessageCount > technicalMessageCount) {
                    console.log(
                      `${studentName}(${messageTime}): ${messageText}`
                    );
                    saveChatMessageToDynamo(
                      meetingId,
                      messageText,
                      studentName,
                      new Date().getTime(),
                      "Student"
                    );
                  }
                }
              } else if (
                messageBubble.getElementsByClassName("tawk-agent-chat").length >
                0
              ) {
                const messageText =
                  messageBubble.getElementsByTagName("span")[0].innerHTML;
                if (messageText !== "New messages") {
                  currentTechnicalMessageCount++;
                  if (currentTechnicalMessageCount > technicalMessageCount) {
                    console.log(`${agentName}(${messageTime}): ${messageText}`);
                    saveChatMessageToDynamo(
                      meetingId,
                      messageText,
                      agentName,
                      new Date().getTime(),
                      "Agent"
                    );
                  }
                }
              }
            }
          } catch (err) {
            console.log("unknown message bubble: ", messageBubble);
          }
        });
        technicalMessageCount = currentTechnicalMessageCount;
      }
    }
  };

  const calculateUnixTimeStampFromTime = (time) => {
    const hour = time.split(":")[0];
    const minute = time.split(":")[1];
    return new Date(
      new Date().getUTCFullYear(),
      new Date().getUTCMonth(),
      new Date().getUTCDate(),
      hour,
      minute,
      59,
      999
    ).getTime();
  };

  const updateLoginTimestamp = (val) => {
    loginTimestamp = val;
  };

  const periodicallyGetTechnicalChatMessages = (meetingId, studentName) => {
    setInterval(
      getLatestTechnicalChatMessage.bind(null, meetingId, studentName),
      5000
    );
  };

  const saveChatMessageToDynamo = async (
    meetingId,
    messageText,
    messageFrom,
    messageTime,
    role
  ) => {
    const databaseParams = {
      ChatId: uniqid(),
      MeetingId: meetingId,
      Message: messageText,
      Person: messageFrom,
      Role: role,
      Time: messageTime,
    };
    await axios.post(`${config.api.invokeUrl}/chats`, databaseParams);
  };

  const getBasicSystemCheckConfiguration = () => {
    const basicSystemCheckConfiguration = [];
    basicSystemCheckConfiguration.push({
      prev: i18n.t("Terms_&_Conditions"),
      renderName: i18n.t("Internet_Connection_Check"),
      render: CheckInternetConnection,
      renderIndex: 0,
    });
    if (information.session.systemCheck.additionalDisplayCheck) {
      basicSystemCheckConfiguration[
        basicSystemCheckConfiguration.length - 1
      ].next = i18n.t("Display_Check");
      basicSystemCheckConfiguration.push({
        next: i18n.t("Webcam_Check"),
        prev: i18n.t("Internet_Connection_Check"),
        renderName: i18n.t("Additional_Display_Check"),
        render: CheckNumberOfDisplays,
        renderIndex: 0,
      });
    } else {
      basicSystemCheckConfiguration[
        basicSystemCheckConfiguration.length - 1
      ].next = i18n.t("Webcam_Check");
    }
    basicSystemCheckConfiguration.push({
      next: i18n.t("Audio_Check"),
      prev: i18n.t("Additional_Display_Check"),
      renderName: i18n.t("Webcam_Check"),
      render: CheckWebcam,
      renderIndex: 0,
    });
    basicSystemCheckConfiguration.push({
      next: i18n.t("Screen_Share_Check"),
      prev: i18n.t("Webcam_Check"),
      renderName: i18n.t("Audio_Check"),
      render: CheckAudio,
      renderIndex: 0,
    });
    return basicSystemCheckConfiguration;
  };

  const getStreamingCheckConfiguration = () => {
    const streamingCheckConfiguration = [];
    streamingCheckConfiguration.push({
      prev: i18n.t("Audio_Check"),
      renderName: i18n.t("Screen_Share_Check"),
      render: CheckScreenShare,
      next: i18n.t("Photo_&_ID_Verification"),
      renderIndex: 0,
    });
    return streamingCheckConfiguration;
  };

  const getOnboardingProcessConfiguration = () => {
    const onboardingProcessConfiguration = [];
    onboardingProcessConfiguration.push({
      prev: i18n.t("Screen_Share_Check"),
      renderName: i18n.t("Photo_&_ID_Verification"),
      render: SecurityCheck,
      next: i18n.t("Exam_Environment_Check"),
      renderIndex: 0,
    });
    onboardingProcessConfiguration.push({
      prev: i18n.t("Photo_&_ID_Verification"),
      renderName: i18n.t("Exam_Environment_Check"),
      render: CheckRoomScan,
      next: i18n.t("Material_Check"),
      renderIndex: 0,
    });
    onboardingProcessConfiguration.push({
      prev: i18n.t("Exam_Environment_Check"),
      renderName: i18n.t("Material_Check"),
      render: CheckAllowedMaterial,
      next: i18n.t("Toilet_Break_Check"),
      renderIndex: 0,
    });
    onboardingProcessConfiguration.push({
      prev: i18n.t("Material_Check"),
      render: CheckToiletBreak,
      renderName: i18n.t("Toilet_Break_Check"),
      next: i18n.t("To_Exam"),
      renderIndex: 0,
    });

    return onboardingProcessConfiguration;
  };

  const getPostSystemCheckConfiguration = () => {
    const postSystemCheckConfiguration = [];
    postSystemCheckConfiguration.push({
      prev: i18n.t("Exam"),
      render: CheckRoomScan,
      renderName: i18n.t("Post_Exam_Environment_Check"),
      next: i18n.t("Exam_Material_Check"),
      renderIndex: 0,
    });
    postSystemCheckConfiguration.push({
      prev: i18n.t("Check_Post_Exam_Environment"),
      render: CheckMaterialDestroyed,
      renderName: i18n.t("Exam_Material_Check"),
      next: i18n.t("Finish_Exam"),
      renderIndex: 0,
    });

    return postSystemCheckConfiguration;
  };

  const mainContent = () => {
    return (
      <React.Fragment>
        <ReactNotification />
        {pageState === 5 ? null : <Header step={pageState} content={content} />}
        {pageState === 0 ? (
          <ExamUrlScreen
            isLoaded={false}
            content={content}
            setPageState={setPageState}
            setInformation={setInformation}
            path={props.location.pathname}
            loadTawkTo={loadTawkTo}
            periodicallyGetTechnicalChatMessages={
              periodicallyGetTechnicalChatMessages
            }
            updateLoginTimestamp={updateLoginTimestamp}
          />
        ) : null}
        {pageState === 1 ? (
          <TermsOfAgreementScreen setPageState={setPageState} secondEnterance={secondEnterance} />
        ) : null}
        {pageState === 2 ? (
          <SystemCheck
            content={content}
            configuration={getBasicSystemCheckConfiguration()}
            pageState={pageState}
            setPageState={setPageState}
            settings={settings}
            information={information}
            setSelectedDevice={setSelectedDevice}
            selectedDevice={selectedDevice}
            {...props}
          />
        ) : null}
        {pageState === 3 ? (
          <SystemCheck
            content={content}
            configuration={getStreamingCheckConfiguration()}
            pageState={pageState}
            setPageState={setPageState}
            settings={settings}
            information={information}
            setSelectedDevice={setSelectedDevice}
            selectedDevice={selectedDevice}
            {...props}
          />
        ) : null}
        {pageState === 4 ? (
          <SystemCheck
            content={content}
            configuration={getOnboardingProcessConfiguration()}
            pageState={pageState}
            setPageState={setPageState}
            settings={settings}
            information={information}
            setSelectedDevice={setSelectedDevice}
            selectedDevice={selectedDevice}
            dependencyReady={dependencyReady}
            {...props}
          />
        ) : null}
        {pageState === 5 ? (
          <ExamStudent
            information={information}
            settings={settings}
            pageState={pageState}
            setPageState={setPageState}
            content={content}
            selectedDevice={selectedDevice}
            incomingCallInformation={incomingCallInformation}
          />
        ) : null}
        {pageState === 6 ? (
          <SystemCheck
            content={content}
            configuration={getPostSystemCheckConfiguration()}
            pageState={pageState}
            setPageState={setPageState}
            settings={settings}
            information={information}
            setSelectedDevice={setSelectedDevice}
            selectedDevice={selectedDevice}
            dependencyReady={dependencyReady}
            {...props}
          />
        ) : null}
        {pageState === 7 ? <ExamReadyScreen /> : null}
        {pageState === 10 ? <DownloadScreen /> : null}
      </React.Fragment>
    );
  };

  return interceptorReady ? mainContent() : <LoadingIcon />;
}

export default withNamespaces()(PreSystemCheck)
