import React from "react";
import ChatComp from "./ChatComp";
import i18n from "../../../config/i18n";
import { withNamespaces } from "react-i18next";

function ChatPanel(props) {
  return (
    <div
      className="card-group"
      style={{
        position: "fixed",
        width: 300,
        bottom: 44,
        right: 10,
      }}
    >
      <ChatComp
        title= {i18n.t("Chat")}
        sendTextMessage={props.sendTextMessage}
        panelVisibility={props.panelVisibility}
      />
    </div>
  );
}
export default withNamespaces()(ChatPanel);
