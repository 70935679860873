/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import ReactLoading from "react-loading";
import { CgRepeat, CgChevronRight } from "react-icons/cg";
import { BsExclamationTriangle } from "react-icons/bs";
// import { BiChevronLeftCircle, BiChevronRightCircle } from "react-icons/bi";
import { MdRadioButtonUnchecked } from "react-icons/md";
import { HiOutlineCheckCircle } from "react-icons/hi";
import i18n from "../../../config/i18n";
import { withNamespaces } from "react-i18next";
const theme = require("../../../config/theme.json");

 function SystemCheckItem(props) {
  const nextButton = document.getElementById("next_button");
  const configuration = props.configuration;
  const currentStep = props.currentStep;
  if (nextButton) {
    if (props.info.ready === "OK") {
      document.getElementById("next_button").style.display = "block";
    } else {
      document.getElementById("next_button").style.display = "none";
    }
  }

  const RenderCurrentCheckStatus = () => {
    if (configuration) {
      // const offsetHeight = BLOCK_HEIGHT;
      const statusList = [];
      for (let i = 0; i < configuration.length; i++) {
        let className = "";
        if (i < configuration.length - 1) {
          className = "systemCheckAllStepsItemBorder";
        }
        if (i < currentStep) {
          statusList.push(
            <div
              className={`d-flex flex-row justify-content-start align-items-center finished-item ${className}`}
              key={i}
            >
              <div className="col-md-2">
                <HiOutlineCheckCircle size={30} />
              </div>
              <div className="col-md-10" style={{ fontSize: 20 }}>
                {configuration[i].renderName}
              </div>
            </div>
          );
        } else if (i === currentStep) {
          if (props.info.ready === "OK") {
            statusList.push(
              <div
                className={`d-flex flex-row justify-content-start align-items-center finished-item ${className}`}
                key={i}
              >
                <div className="col-md-2">
                  <HiOutlineCheckCircle size={30} />
                </div>
                <div className="col-md-10" style={{ fontSize: 20 }}>
                  {configuration[i].renderName}
                </div>
              </div>
            );
          } else {
            statusList.push(
              <div
                className={`d-flex flex-row justify-content-start align-items-center proceed-item ${className}`}
                key={i}
              >
                <div className="col-md-2">
                  <ReactLoading
                    type="bubbles"
                    // color="rgba(141, 142, 157, 1)"
                    color="rgba(90, 188, 42, 1)"
                    height="30px"
                    width="30px"
                  />
                </div>
                <div className="col-md-10" style={{ fontSize: 20 }}>
                  {configuration[i].renderName}
                </div>
              </div>
            );
          }
        } else {
          statusList.push(
            <div
              className={`d-flex flex-row justify-content-start align-items-center proceed-item ${className}`}
              key={i}
            >
              <div className="col-md-2">
                <MdRadioButtonUnchecked size={30} />
              </div>
              <div className="col-md-10" style={{ fontSize: 20 }}>
                {configuration[i].renderName}
              </div>
            </div>
          );
        }
      }
      return (
        <div
          className="col-md-2"
          style={{
            backgroundColor: "white",
            borderTopRightRadius: 20,
            borderBottomRightRadius: 20,
            margin: "auto",
          }}
        >
          {statusList}
        </div>
      );
    }

    return null;
  };

  const RenderInfoReadyMessage = () => {
    if (props.info.ready === "OK") {
      return (
        <div
          className="d-flex flex-row justify-content-center align-items-center col-md-10"
          style={{
            borderColor: "rgba(90, 188, 42, 1)",
            fontWeight: 500,
            backgroundColor: "rgba(90, 188, 42, 1)",
            color: "white",
            borderStyle: "solid",
            borderRadius: 10,
            marginVertical: 20,
            padding: 5,
            fontSize: 16,
            // height: 36,
            marginTop: 10,
          }}
        >
         
          {props.info.waitMessage}
          {(configuration[currentStep].renderName === i18n.t("Exam_Environment_Check") || 
            configuration[currentStep].renderName === i18n.t("Post_Exam_Environment_Check") || 
            configuration[currentStep].renderName === i18n.t("Material_Check") || 
            configuration[currentStep].renderName === i18n.t("Exam_Material_Check")) 
            &&
            <div className="ml-3">
              <ReactLoading
                type="bars"
                color="#29377e"
                height="30px"
                width="30px"
              />
            </div>
          }
          <div className="ml-3">
            {props.info.successLoading ? props.info.successLoading : null}
          </div>
        </div>
      );
    } else if (props.info.ready === "FAILED") {
      return (
        <div
          className="d-flex flex-row justify-content-center align-items-center col-md-10"
          style={{
            borderColor: "#B80F0A",
            borderStyle: "solid",
            borderRadius: 10,
            marginVertical: 20,
            padding: 5,
            fontSize: 16,
            backgroundColor: "#B80F0A",
            color: "white",
            height: 36,
            marginTop: 10,
          }}
        >
          {props.info.waitMessage}
          <div className="ml-3">
            {props.info.successLoading ? props.info.successLoading : null}
          </div>
        </div>
      );
    } else if (props.info.ready === "WAITING") {
      return (
        <div
          id="wait_message_div"
          className="d-flex flex-row justify-content-center align-items-center col-md-10"
          style={{
            borderColor: "#FFBF00",
            borderStyle: "solid",
            borderRadius: 10,
            marginVertical: 20,
            padding: 5,
            fontSize: 16,
            fontWeight: "bold",
            backgroundColor: "#FFBF00",
            color: "#29377e",
            height: 36,
            marginTop: 10,
          }}
        >
          {props.info.waitMessage}
          <div className="ml-3">
            <ReactLoading
              type="bars"
              color="#29377e"
              height="30px"
              width="30px"
            />
          </div>
        </div>
      );
    }
  };
  // const RenderNavigationHeader = () => {
  //   return (
  //     <div className="ml-auto col-md-9 p-0">
  //       <div className="d-flex flex-row justify-content-start">
  //         <div
  //           className="d-flex flex-row justify-content-center align-items-center systemCheckNavigation mr-3"
  //           style={{ fontSize: 16 }}
  //         >
  //           <BiChevronLeftCircle size={18} className="mr-1" />
  //           <span>{props.prev}</span>
  //         </div>
  //         <div
  //           style={{ borderRight: "2px solid rgba(141, 141, 142, 0.7)" }}
  //         ></div>
  //         <div
  //           className="d-flex flex-row justify-content-center align-items-center systemCheckNavigation ml-3"
  //           style={{ fontSize: 16 }}
  //         >
  //           {props.next}
  //           <BiChevronRightCircle size={18} className="ml-1" />
  //         </div>
  //       </div>
  //       <div className="col-md-10 p-0">
  //         <div
  //           style={{
  //             marginTop: 2,
  //             borderBottom: "2px solid rgba(141, 141, 142, 0.7)",
  //           }}
  //         ></div>
  //       </div>
  //     </div>
  //   );
  // };
  const RenderIconPanel = () => {
    return (
      <div
        className={`col-md-10 d-flex align-items-center justify-content-center fixedHeight`}
        id="webcam_div"
        style={{ marginTop: 10 }}
      >
        {props.Icon}
      </div>
    );
  };
  const RenderRequirementPanel = () => {
    return (
      <div className="col-md-10 mt-3" id="requirement_panel">
        {/* <div className="col-md-9 m-auto"> */}
        {/* <div
          className="col-md-2 ml-auto mr-auto"
          style={{
            color: "rgba(90, 188, 42, 1)",
            fontWeight: "bold",
            borderBottom: "1px solid rgba(90, 188, 42, 1)",
            marginBottom: 5,
            fontSize: 16,
          }}
        >
          Requirement
        </div> */}
        <div
          className="col-md-9 ml-auto mr-auto"
          style={{
            color: "rgba(141, 141, 142, 1)",
            fontWeight: 500,
            fontSize: 16,
          }}
        >
          {props.info.description}
        </div>
      </div>
      // </div>
    );
  };

  const RenderProceedButtons = () => {
    return (
      <div className="col-md-10" style={{ marginTop: 30 }}>
        <div className="col-md-10 m-auto">
          {props.info.repeatButtonText ? null : (
            <div className="col-md-6 ml-auto" id="repeatable_button_top_div">
              <div
                style={{
                  fontSize: 16,
                  color: "rgba(141, 141, 142, 1)",
                  fontWeight: "bold",
                  marginBottom: 10,
                }}
              >
                {i18n.t("Repeat_check_for")}:
              </div>
            </div>
          )}

          <div className="d-flex flex-row">
            <div className="col-6" id="next_button_div">
              <button
                id="next_button"
                className={`${theme.btn_save_small} m-auto pr-4 col-md-8`}
                onClick={props.buttonHandler}
                style={{
                  display: "none",
                }}
              >
                {props.info.buttonIcon ? (
                  props.info.buttonIcon
                ) : (
                  <CgChevronRight size={40} style={{ marginRight: 10 }} />
                )}

                {props.info.buttonText}
              </button>
            </div>
            <div className="col-6" id="repeatable_button_div">
              {!props.isClose ? (
                props.repeatableButton ? (
                  <button
                    className={`${theme.btn_save_small} m-auto repeat-button repeat col-md-8`}
                    onClick={props.handleRepeatClick}
                  >
                    <CgRepeat size={40} style={{ marginRight: 10 }} />
                    {configuration[currentStep].renderName.split("Check")[0]}
                  </button>
                ) : props.info.repeatButtonText === "NONE" ? null : (
                  <button
                    className="btn buttons fontbold repeat-button repeat"
                    onClick={props.handleRepeatButton}
                    id="take_a_photo"
                    disabled={props.info.repeatButtonDisabled}
                  >
                    <CgRepeat size={40} style={{ marginRight: 10 }} />
                    {props.info.repeatButtonIcon}
                    {props.info.repeatButtonText}
                  </button>
                )
              ): null}
            </div>
          </div>
        </div>
      </div>
    );
  };
  const RenderNoteThatPanel = () => {
    return (
      <div
        className="col-md-9"
        style={{
          paddingTop: 30,
          margin: "auto",
        }}
      >
        <div
          className="d-flex flex-row justify-content-center align-items-center pt-2"
          style={{
            backgroundColor: "rgba(242,242,242,1)",
            color: "rgba(141, 141, 142, 1)",
            height: 90,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          }}
        >
          <div className="ml-auto mr-auto" style={{ fontSize: 20 }}>
            <BsExclamationTriangle size={40} className="mr-3" />
            <span style={{ fontWeight: "bolder" }}>{i18n.t("Please_Note")}!</span>
          </div>
          <div
            className="col-md-9 ml-auto mr-auto"
            style={{
              color: "rgba(141, 141, 142, 1)",
              fontWeight: 500,
              textAlign: "left",
              fontSize: 16,
            }}
          >
            {i18n.t("These_checks_do_not_guarantee_that_your_system_will_work_during_the_exam")}
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="col-md-12" style={{ minHeight: "calc(100vh - 110px)" }}>
        {/* {RenderNavigationHeader()} */}
        <div
          className="d-flex flex-row justify-content-center align-items-start"
          id="system_check_main_block"
          style={{ minHeight: "calc(100vh - 230px)" }}
        >
          {RenderCurrentCheckStatus()}
          <div className="ml-auto col-md-9">
            {RenderInfoReadyMessage()}
            {RenderIconPanel()}
            {RenderRequirementPanel()}
            {RenderProceedButtons()}
          </div>
        </div>
        {RenderNoteThatPanel()}
      </div>
    </React.Fragment>
  );
}
export default withNamespaces() (SystemCheckItem);
