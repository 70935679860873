import React, { useState, useEffect } from "react";
import SystemCheckItem from "./SystemCheckItem";
import { ImDisplay } from "react-icons/im";
import i18n from "../../../config/i18n";
import { withNamespaces } from "react-i18next";

function CheckNumberOfDisplays(props) {
  const [info, setInfo] = useState({
    title: i18n.t("Checking_Additional_Displays"),
    ready: i18n.t("WAITING"),
    waitMessage: i18n.t("Please_wait_We_are_checking_your_displays"),
    infoCover: "col-6",
    description: (
      <div>
        {i18n.t("This_step_will_check_your_additional_displays_You_are_allowed_to_use")}
        {" "}
        <span className="font-weight-bold"> {i18n.t("only_one_display_monitor")}</span> 
        {i18n.t("to_pass_this_step")} .
      </div>
    ),
    descriptionCover: "col-6",
    buttonText: i18n.t("Next_Step"),
  });

  useEffect(() => {
    const getNumberOfScreens = async () => {
      const enumeratedDevices = await navigator.mediaDevices.enumerateDevices();
      for (const device of enumeratedDevices) {
        if (device.deviceId !== "default" && device.kind === "videoinput") {
          setInfo((info) => ({
            ...info,
            ready: "OK",
            waitMessage: i18n.t("Success_Please_click_Next_Step_to_proceed"),
          }));
          return;
        }
      }
      setInfo((info) => ({
        ...info,
        ready: "OK",
        waitMessage: i18n.t("Success_Please_click_Next_Step_to_proceed"),
      }));
      // setInfo((info) => ({
      //   ...info,
      //   ready: "FAILED",
      //   waitMessage:
      //     "Additional display detected. Please unplug/disconnect any additional displays and repeat the system check",
      // }));
    };
    getNumberOfScreens();
  }, []);

  return (
    <SystemCheckItem
      Icon={
        <ImDisplay
          size={200}
          color={info.ready === "OK" ? "#29377e" : "gray"}
        />
      }
      info={info}
      buttonHandler={props.buttonHandler}
      next={props.next}
      prev={props.prev}
      handleRepeatClick={props.handleRepeatClick}
      repeatableButton={true}
      configuration={props.configuration}
      currentStep={props.currentStep}
    />
  );
}
export default withNamespaces()(CheckNumberOfDisplays)